import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { ApprovalMatrixService } from 'src/app/qubit-x/portfolio/check-list-pop-up/approval-matrix/approval-matrix.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { TranslateService } from 'src/app/services/translation.service';
import { ApprovalSystemService } from 'src/app/services/approval-system.service';
import { UtilService } from 'src/app/utils/util.service';
import { InvestmentSummaryService } from '../investment-page-summary-v2/investment-summary.service';
import { cloneDeep } from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class CheckListService {
  constructor(private ds: DataService,
    private approvalMatrixService: ApprovalMatrixService,
    private ums: UserManagementService,
    private utilService: UtilService,
    private invSummaryService: InvestmentSummaryService,
    private router: Router,
    private translateService: TranslateService) {

  }

  blurBG = false;
  dataLoaded = false;

  companies = [];
  navigationLabels=[];

  async init(fundId) {
    const userDetails = this.ums.getSelectedUserDetails();

    const reqBody = {
      "orgId": userDetails.organization.id,
      "fundId": fundId,
      "userId": userDetails.id
    }

    try {
      const response: any = await this.ds.getCompaniesForCheckListBeta(reqBody).toPromise()
      console.log("Companies For CheckList fetched Successfully!")

      const allComps = response.body["response"].sort((a, b) => (a?.companyName > b?.companyName ? 1 : -1))

      let finalCompList = [];

      if(this.router.url.includes('company-valuation') && this.invSummaryService.companyId){

        const subjectComp = allComps.filter(c => c.companyId === this.invSummaryService.companyId);
  
        const nonSubjectComps = allComps.filter( c => c.companyId !== this.invSummaryService.companyId);
  
        finalCompList = subjectComp.concat(nonSubjectComps);

      } else {

        finalCompList = cloneDeep(allComps);

      }

      await this.getAllApprovalDetailsByCompanies(finalCompList);

      this.companies = finalCompList;

      this.ds.getNavigationLabelsForCompanyCheckList().subscribe(data => {
        this.navigationLabels = data.body["response"];

        this.navigationLabels = this.navigationLabels.map(nav => {
          nav.checklistLabel = this.getTranslationByCheckListKey(nav.checklistKey);
          return nav
        })
      })

    } catch (error) {

      console.log("Failed to fetch companies for CheckList!, error: ", error)

      this.utilService.showMessage("Failed to fetch companies for CheckList.", (this.translateService.getLabel("ok")));

    }

  }

  getTranslationByCheckListKey(key){
    switch (key) {
      case "updated-metric":
        return this.translateService.getLabel("update_financials")

      case "adjusted-metric":
        return this.translateService.getLabel("adjust_financials")
          
      case "ccm-comp-list":
        return this.translateService.getLabel("comparable_company_list")
      
      case "ccm-multiple-adj":
        return this.translateService.getLabel("multiples_adjustment")

      case "ccm-analysis":
        return this.translateService.getLabel("weights_to_multiples")
      
      case "stake":
        return this.translateService.getLabel("stake")
    
      default:
        return key;
    }
  }

  async getAllApprovalDetailsByCompanies(companies) {
    const allApprovalIds = [];



    companies.forEach((eachDate) => {

      if(!!eachDate.approvalSystem && (eachDate.approvalSystem.status != 'APPROVED' && eachDate.approvalSystem.status != 'REJECTED')  && eachDate.approvalSystem.userRole == 'APPROVER' && (eachDate.approvalSystem.approvalObjectStatus == 'APPROVAL_IN_PROGRESS' || eachDate.approvalSystem.approvalObjectStatus == 'INITIATED')){
        eachDate.pendingAprroval = true;
      }else{
        eachDate.pendingAprroval = false;
      }

      if(eachDate.approvalRequestId){
        allApprovalIds.push(eachDate.approvalRequestId)
      }

      eachDate.selectedCondition = {
        "conditionName": "",
        "id": ""
      }

    });

    const user = this.ums.getSelectedUserDetails();

    try {
      this.approvalMatrixService.allApprovalDetails = await this.approvalMatrixService.getApprovalDataForAllIds(allApprovalIds, user.organization.id);
    } catch(e) {
      console.log("Error: Failed to load approval routes in checklist", e);
    }
  }     

  async getAllApprovalDetails() {
    await this.getAllApprovalDetailsByCompanies(this.companies);
  }

  async setNewApprovalIdToCompany(latestValDateId, approvalId) {
    const comp = this.companies.find(c => c.latestValuationDateId == latestValDateId);

    if(comp) {
      comp.approvalRequestId = approvalId;
      await this.getAllApprovalDetailsByCompanies(this.companies);
    }
  }
}