import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment/moment';
import { DataService } from 'src/app/services/data.service';
import { ImageLoadService } from 'src/app/services/image-load.service';
import { MessageService } from 'src/app/services/message.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';
import { CheckListService } from '../check-list.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/utils/toast.service';
import { LabelService } from 'src/app/services/labels.service';
import { PortFolioSummaryServiceV2 } from '../../portfolio-summary-v2/portfolio-summary-v2.service';
import { ApprovalMatrixService } from 'src/app/qubit-x/portfolio/check-list-pop-up/approval-matrix/approval-matrix.service';
import { TranslateService } from 'src/app/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JsonPipe } from '@angular/common';
import { ApprovalSystemService } from 'src/app/services/approval-system.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { InvestmentSummaryService } from '../../investment-page-summary-v2/investment-summary.service';


@Component({
  selector: 'app-company-list-in-check-list',
  templateUrl: './company-list-in-check-list.component.html',
  styleUrls: ['./company-list-in-check-list.component.scss']
})
export class CompanyListInCheckListComponent implements OnInit {

  @Input() companylistForCheckList; 
  @Output() refreshChecklistOnRejection = new EventEmitter();

  @ViewChild('downloadLink', { static: true }) downloadLink: ElementRef;

  excelData;
  excelDownloading = false;

  approvalIniationInProgress = {};
  approvalComment = "";
  approvalCompObject;

  checkListValues;
  options=[];

  isApprovalAccessible: boolean = false;

  approvalConditionData = {} as any;

  initiatorsGroupMembers = [];

  currentUser = {} as any;

  showAllApprovals = true;
  showMyApprovalsToggle = false;
  showNoApprovalPendingMessage = false;

  constructor(public loaderService: ImageLoadService,
              public dataService: DataService,
              public utilService: UtilService, 
              public ums: UserManagementService,
              public ms: MessageService,
              public checkListService : CheckListService,
              private ps: PortFolioSummaryServiceV2,
              public approvalService: ApprovalSystemService,
              private changeDetector: ChangeDetectorRef,
              private toastService:ToastService,
              public labelService: LabelService,
              public router: Router,
              public invSummaryService: InvestmentSummaryService,
              private navigationService: NavigationService,
              public approvalMatrixService: ApprovalMatrixService,
              public translateService: TranslateService,
              private modalService : NgbModal,
              ) { }

  ngOnInit(): void {
    this.currentUser = this.ums.getSelectedUserDetails();

    //check whether user has access to  Approval System
    const isUserOrgAdmin = this.navigationService.menuList.find(item => item?.menuKey == "orgadmin" && item?.show == "SHOW");
    if(!!isUserOrgAdmin){
      const ifApprovalSystemExists = isUserOrgAdmin.subMenuItems.find(subItem => subItem?.toolKey == "approval_matrix" && subItem?.show == "SHOW")
      if(!!ifApprovalSystemExists){
        this.isApprovalAccessible = true;
      }
    }

    //get approval history for comment box of approval checklist
    this.checkListService.companies.forEach(comp => {
      this.approvalService.getUserHistory(comp);
    })

  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  // Getting check/uncheck value for checkbox
  // Getting Approval System details on expansion of each company dropdown
  getSelectedCompanyDetails(comp, initAP, refresh?) {

    const userDetails = this.ums.getSelectedUserDetails();

    comp.expanded = true;

    this.dataService.getCheckListValuesForCompanyCheckList(comp.latestValuationDateId).subscribe(data => {
      this.checkListValues = data.body["response"]
      let options = [];

      this.checkListService.navigationLabels.forEach(navLabel => {
        const checkedExist = this.checkListValues.find(row => { return row.checklistId == navLabel.id && comp.latestValuationDateId == row.valuationDateId })

        let obj = {
          label: navLabel.checklistLabel,
          id: navLabel.id,
          checked: false,
          navigationKey: navLabel.checklistKey
        }

        if (checkedExist) {
          obj.checked = checkedExist.checklistChecked
        }

        options.push(obj)
      })
      comp.options = options;
    })


    //New Approval System:

    this.approvalService.init(comp);

    //get approval history for comment box of approval checklist
    this.approvalService.getUserHistory(comp);

    const reqBody = {
      "orgId": userDetails.organization.id,
      "fundId": this.ps.selectedFundId,
      "companyId": comp.companyId,
      "userId": userDetails.id,
      "module": "EQUITY",
      "approvalObjectIds": [comp.latestValuationDateId]
    }

    this.dataService.getApprovalObjectStatus(reqBody).subscribe(data => {
      const approvalSystemData = data.body["response"][comp.latestValuationDateId]

      // if (!!approvalSystemData) {
        comp.approvalSystem = approvalSystemData;
      // }

      if(!!comp.approvalSystem || this.approvalService.allEnabledConditions.length != 2){
        comp.selectedCondition = {
          "conditionName": !!comp.approvalSystem ? Object.values(comp.approvalSystem.condition)[0] : "",
          "id": !!comp.approvalSystem ? Object.keys(comp.approvalSystem.condition)[0] : ""
        }
      }

      if (!!comp.approvalCondition) {
        comp.selectedCondition['id'] = comp.approvalCondition;

        let updatedCondition = this.approvalService.allEnabledConditions.find(c => c.id == comp.approvalCondition);

        if(updatedCondition){
          comp.selectedCondition['conditionName'] = updatedCondition.conditionName;
        }

      }

      this.utilService.closeAllPopups();

      if(refresh){
        this.refreshChecklistOnRejection.emit();
      }

    })

    if (initAP) {
      this.initApprovalMatrixData(comp);
    }
  }

  onMyApprovalsToggle(){
    this.showAllApprovals = !this.showAllApprovals;

    this.checkListService.companies.forEach( comp => {
      comp.expanded = false;
    })

    const pendingApprovals = this.checkListService.companies.filter(comp => comp.pendingAprroval)

    if(pendingApprovals.length == 0 && this.showMyApprovalsToggle){
      this.showNoApprovalPendingMessage = true;
    }else{
      this.showNoApprovalPendingMessage = false;
    }
  }

  showComment(comp){
    comp.showComment = !comp.showComment;
  }

  initApprovalMatrixData(comp) {    
    this.approvalConditionData = {};
    this.initiatorsGroupMembers = [];
    comp.approval = {};

    this.dataService.getApprovalConditionForSubscribe(this.currentUser.organization.id, comp.companyId, this.ps.selectedFundId).subscribe(res => {

      this.approvalConditionData = res.body["response"];

      if(this.approvalConditionData && this.approvalConditionData.approvers[0].type == "group") {

        this.dataService.getGroupByIdForSubscribe(this.approvalConditionData.approvers[0].id).subscribe(res => {

          this.initiatorsGroupMembers = res.body["response"].members;

          const approvalStatus = this.approvalMatrixService.getApprovalStatus(this.approvalMatrixService.allApprovalDetails, this.approvalConditionData, this.initiatorsGroupMembers, comp);

          console.log("Approval status for " + comp.companyName, approvalStatus);

          comp.approval = approvalStatus;
        }, error => {
          console.log("Error: Failed to get approval matrix group for group id" + this.approvalConditionData.approvers[0].id);
        })
      }
    }, error => {
      console.log("Error: Failed to get approval conditions for company ", comp.companyName, comp.companyId);
    })
  }

  optionSelect(labelObj, comp){
    let saveCheckListPayload = {
      "checklistId": "",
      "companyId": "",
      "valuationDateId": "",
      "checklistChecked": false,
      "approvalRoute": "",
      "createdBy": ""
     };

    saveCheckListPayload.checklistChecked = labelObj.checked;
    saveCheckListPayload.checklistId = labelObj.id;
    saveCheckListPayload.companyId = comp.companyId;
    saveCheckListPayload.valuationDateId = comp.latestValuationDateId;
    saveCheckListPayload.approvalRoute = "";
    saveCheckListPayload.createdBy = this.ums.getSelectedUserDetails().userName;

    this.dataService.getSaveCompanyCheckList(saveCheckListPayload).subscribe(data => {
      this.getSelectedCompanyDetails(comp, false)
    })
  }

  getExcelDownloadForApprovalCompanyChecklistV2(excelData, companyName, latestValuationDate) {
    const values = [];
    let customRow = [this.getCellObject("text", "", false, "", "", false, "", "ffd62a", true), 
    this.getCellObject("text", "", false, "", "", false, "", "ffd62a", true)];
    
    excelData.valuationDates.forEach(ele => {
      customRow.push(this.getCellObject("text", "", false, "", "", false, "", "ffd62a", true))
    });

    values.push(customRow);

    values.push([this.getCellObject("text", ""), this.getCellObject("text", companyName, true), this.getCellObject("text", (moment(latestValuationDate).format("ll")))])
    values.push([this.getCellObject("text", "")])
    values.push([this.getCellObject("text", ""), this.getCellObject("text", "Diluted Ownership"), this.getCellObject("number", excelData.valuationSummary.stake[excelData.valuationSummary.stake.length - 1] / 100, false, "", "%")])
    values.push([this.getCellObject("text", "")])

    let valuationDateList = [];
    valuationDateList.push(this.getCellObject("text", ""), this.getCellObject("text", "Particulars" + "(" + excelData.valuationSummary.currency[0] + " Mn)", true, "", "", "", "1D1563", "D3CFFF"));

    excelData.valuationDates.forEach((row, i) => { (valuationDateList.push(this.getCellObject("text", moment(row).format("ll"), true, "", "", false, "1D1563", "D3CFFF"))) })

    values.push(valuationDateList);

    values.push([this.getCellObject("text", "")])

    // Trading Comps Analysis
    values.push([this.getCellObject("text", ""), this.getCellObject("text", "Trading Comps Analysis")]);

    // Primary Multiple
    const primaryMultipleRow = [this.getCellObject("text", ""), this.getCellObject("text", "Primary Multiple")];
    excelData.primaryMultipleValues.primaryMultiple.forEach((row, i) => {
      primaryMultipleRow.push(this.getCellObject("text", this.labelService.labels[row]));
    });
    values.push(primaryMultipleRow);

    // Primary Metric
    const primaryMetricRow = [this.getCellObject("text", ""), this.getCellObject("text", "Primary Metric")];
    excelData.primaryMultipleValues.metric.forEach((row, i) => {
      const label = excelData.primaryMultipleValues.fy[i] ? excelData.primaryMultipleValues.fy[i] + " " + this.labelService.labels[row + "_metric"] : "";
      primaryMetricRow.push(this.getCellObject("text", label));
    });
    values.push(primaryMetricRow);

    // Adjusted Metric
    const adjustedMetricRow = [this.getCellObject("text", ""), this.getCellObject("text", "Adjusted Metric")];
    excelData.primaryMultipleValues.adjustedMetric.forEach((row, i) => {
      adjustedMetricRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(adjustedMetricRow);

    // Market Multiple (Primary)
    const marketMultipleRow = [this.getCellObject("text", ""), this.getCellObject("text", "Market Multiple (x)")];
    excelData.primaryMultipleValues.marketMultiple.forEach((row, i) => {
      marketMultipleRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(marketMultipleRow);

    // Discount/Premium to Multiples
    const discountPremiumRow = [this.getCellObject("text", ""), this.getCellObject("text", "Discount/ Premium to Multiples (%)")];
    excelData.primaryMultipleValues.discountPremium.forEach((row, i) => {
      discountPremiumRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(discountPremiumRow);

    // Net Multiples (Primary)
    const netMultiplesRow = [this.getCellObject("text", ""), this.getCellObject("text", "Net Multiples (x)")];
    excelData.primaryMultipleValues.concludedMultiple.forEach((row, i) => {
      netMultiplesRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(netMultiplesRow);

    // Weights to Multiple
    const weightsToMultipleRow = [this.getCellObject("text", ""), this.getCellObject("text", "Weights to Multiple (%)")];
    excelData.primaryMultipleValues.weightsToMultiple.forEach((row, i) => {
      weightsToMultipleRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(weightsToMultipleRow);
    values.push([this.getCellObject("text", "")])

    // Secondary Multiple
    const secondaryMultipleRow = [this.getCellObject("text", ""), this.getCellObject("text", "Secondary Multiple")];
    excelData.secondaryMultipleValues.primaryMultiple.forEach((row, i) => {
      secondaryMultipleRow.push(this.getCellObject("text", this.labelService.labels[row]));
    });
    values.push(secondaryMultipleRow);

    // Secondary Metric
    const secondaryMetricRow = [this.getCellObject("text", ""), this.getCellObject("text", "Secondary Metric")];
    excelData.secondaryMultipleValues.metric.forEach((row, i) => {
      const label = excelData.secondaryMultipleValues.fy[i] ? excelData.secondaryMultipleValues.fy[i] + " " + this.labelService.labels[row + "_metric"] : "";
      secondaryMetricRow.push(this.getCellObject("text", label));
    });
    values.push(secondaryMetricRow);

    // Adjusted Metric (Secondary)
    const adjustedMetricSecondaryRow = [this.getCellObject("text", ""), this.getCellObject("text", "Adjusted Metric")];
    excelData.secondaryMultipleValues.adjustedMetric.forEach((row, i) => {
      adjustedMetricSecondaryRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(adjustedMetricSecondaryRow);

    // Market Multiple (Secondary)
    const marketMultipleSecondaryRow = [this.getCellObject("text", ""), this.getCellObject("text", "Market Multiple (x)")];
    excelData.secondaryMultipleValues.marketMultiple.forEach((row, i) => {
      marketMultipleSecondaryRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(marketMultipleSecondaryRow);

    // Discount/Premium to Multiples (Secondary)
    const discountPremiumSecondaryRow = [this.getCellObject("text", ""), this.getCellObject("text", "Discount/ Premium to Multiples (%)")];
    excelData.secondaryMultipleValues.discountPremium.forEach((row, i) => {
      discountPremiumSecondaryRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(discountPremiumSecondaryRow);

    // Concluded Multiple (Secondary)
    const concludedMultipleSecondaryRow = [this.getCellObject("text", ""), this.getCellObject("text", "Concluded multiple (x)")];
    excelData.secondaryMultipleValues.concludedMultiple.forEach((row, i) => {
      concludedMultipleSecondaryRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(concludedMultipleSecondaryRow);

    // Weights to Multiple (Secondary)
    const weightsToMultipleSecondaryRow = [this.getCellObject("text", ""), this.getCellObject("text", "Weights to Multiple (%)")];
    excelData.secondaryMultipleValues.weightsToMultiple.forEach((row, i) => {
      weightsToMultipleSecondaryRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(weightsToMultipleSecondaryRow);
    values.push([this.getCellObject("text", "")])

    // Valuation Summary
    const valuationSummaryRow = [this.getCellObject("text", ""), this.getCellObject("text", "Valuation Summary")];
    values.push(valuationSummaryRow);

    if (excelData.valuationSummary && excelData.valuationSummary.algorithms) {
      excelData.valuationSummary.algorithms.forEach((algoArray, i) => {
        algoArray.unshift("");
        algoArray[1] = this.ps.displayAlgorithmsName(algoArray[1]) + " (%)";
        const formattedAlgoArray = algoArray.map(cell => {
          if (typeof cell === "number") {
            return this.getCellObject("number", cell, false, 2);
          } else {
            return this.getCellObject("text", cell);
          }
        });
        values.push(formattedAlgoArray);
      });
    }
    values.push([this.getCellObject("text", "")])

    // Concluded Enterprise Value
    const concludedEnterpriseValueRow = [this.getCellObject("text", ""), this.getCellObject("text", "Concluded Enterprise Value")];
    excelData.valuationSummary.concludedEnterpriseValue.forEach((row, i) => {
      concludedEnterpriseValueRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(concludedEnterpriseValueRow);

    // Net Debt
    const netDebtRow = [this.getCellObject("text", ""), this.getCellObject("text", "Net Debt")];
    excelData.valuationSummary.netDebt.forEach((row, i) => {
      netDebtRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(netDebtRow);

    // Equity Value
    const equityValueRow = [this.getCellObject("text", ""), this.getCellObject("text", "Equity Value")];
    excelData.valuationSummary.equityValue.forEach((row, i) => {
      equityValueRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(equityValueRow);

    // Stake (%)
    const stakeRow = [this.getCellObject("text", ""), this.getCellObject("text", "Stake (%)")];
    excelData.valuationSummary.stake.forEach((row, i) => {
      stakeRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(stakeRow);

    // Fair Value
    const fairValueRow = [this.getCellObject("text", ""), this.getCellObject("text", "Fair Value")];
    excelData.valuationSummary.fairValue.forEach((row, i) => {
      fairValueRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(fairValueRow);

    // Realised Proceeds
    const realisedProceedsRow = [this.getCellObject("text", ""), this.getCellObject("text", "Realised Proceeds")];
    excelData.valuationSummary.equityValue.forEach((row, i) => {
      realisedProceedsRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(realisedProceedsRow);

    // Investment Amount
    const investmentAmountRow = [this.getCellObject("text", ""), this.getCellObject("text", "Investment Amount")];
    excelData.valuationSummary.investmentAmount.forEach((row, i) => {
      investmentAmountRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(investmentAmountRow);

    // MOIC (x)
    const moicRow = [this.getCellObject("text", ""), this.getCellObject("text", "MOIC (x)")];
    excelData.valuationSummary.moic.forEach((row, i) => {
      moicRow.push(this.getCellObject("number", row, false, 2));
    });
    values.push(moicRow);

    // IRR (%)
    const irrRow = [this.getCellObject("text", ""), this.getCellObject("text", "IRR (%)")];
    if (excelData.valuationSummary && excelData.valuationSummary.grossIRR) {
      excelData.valuationSummary.grossIRR.forEach((row, i) => {
        irrRow.push(this.getCellObject("number", row, false, 2));
      });
    }
    values.push(irrRow);
    values.push([this.getCellObject("text", "")])

    // Comparable Company List
    if (excelData.columnHeaders && excelData.columnHeaders.length > 0) {
      const headerRow = [this.getCellObject("text", "")];
      headerRow.push(this.getCellObject("text", "Comparable Company List"));
      excelData.columnHeaders.forEach(header => {
        headerRow.push(this.getCellObject("text", header));
      });
      values.push(headerRow);
    }

    if (excelData.tradingComps && excelData.tradingComps.length > 0) {
      let comparableCompanyListAdded = false;
      excelData.tradingComps.forEach((row) => {
        if (Array.isArray(row)) {
          const dataRow = [this.getCellObject("text", "")];
          if (!comparableCompanyListAdded) {
            dataRow.push(this.getCellObject("text", "Comparable Company List"));
            comparableCompanyListAdded = true;
          } else {
            dataRow.push(this.getCellObject("text", ""));
          }
          let isFirstCompany = true;
          row.forEach((cell) => {
            if (!isFirstCompany) {
              // Add an empty cell between companies
              dataRow.push(this.getCellObject("text", ""));
            }
            isFirstCompany = false;
            dataRow.push(this.getCellObject("text", cell, false, "", "", true));
          });
          values.push(dataRow);
        }
      });
    }

    return [{ "tabName": "Excel Download", "data": { "headers": [], "values": values }, "prepareCustomHeader": true }];
  }

  getCellObject(type, value, isBold?, decimal?, suffix?, textWrap?, color?, background?, shortRowHeight?) {

    const obj = {
      type: type,
      value: value,
      bold: isBold == true ? true : false,
      suffix: suffix ? suffix : "",
      textWrap: textWrap,
      color: color,
      background: background
    };

    if (decimal) {
      Object.assign(obj, {"decimal": decimal});
    }

    if(shortRowHeight){
      Object.assign(obj, {"shortRowHeight": true});
    }

    return obj;

  }

  getExcelDownloadForApprovalCompanyChecklist(excelData, companyName, latestValuationDate){
    // let excelData = this.excelData;
    const values = [];
    let valuationDateList = ["","Particulars" + "("+ excelData.valuationSummary.currency[0] +  " Mn)"];
    excelData.valuationDates.forEach((row,i) => {(valuationDateList.push(moment(row).format("ll"))) })

    let companyList: any[] = [""]
    excelData.tradingComps.forEach((row, i) => { companyList.push(row) })

    let primaryMultiple = ["","Primary Multiple"]
    excelData.primaryMultipleValues.primaryMultiple.forEach((row, i) => { primaryMultiple.push(this.labelService.labels[row])})

    let primaryMetric =["","Primary Metric"]
    excelData.primaryMultipleValues.metric.forEach((row, i) => { 
      const label = excelData.primaryMultipleValues.fy[i] ? 
      excelData.primaryMultipleValues.fy[i] + " " + this.labelService.labels[row + "_metric"]
      : ""
      primaryMetric.push(label ? label : "")
    })

    let adjustedMetric = ["","Adjusted Metric"]
    excelData.primaryMultipleValues.adjustedMetric.forEach((row, i) => { adjustedMetric.push(row)})

    let marketMultiple = ["","Market Multiple (x)"]
    excelData.primaryMultipleValues.marketMultiple.forEach((row, i) => { marketMultiple.push(row)})

    let discountPremiumToMultiples = ["","Discount/ Premium to Multiples (%)"]
    excelData.primaryMultipleValues.discountPremium.forEach((row, i) =>{ discountPremiumToMultiples.push(row)})

    let netMultiple = ["","Net Multiples (x)"]
    excelData.primaryMultipleValues.concludedMultiple.forEach((row, i) =>{ netMultiple.push(row)})

    let weightsToMultiple = ["","Weights to Multiple (%)"]
    excelData.primaryMultipleValues.weightsToMultiple.forEach((row, i) => { weightsToMultiple.push(row)})

    // Secondary Metric

    let secondaryMultiple = ["","Secondary Multiple"]
    excelData.secondaryMultipleValues.primaryMultiple.forEach((row, i) => { secondaryMultiple.push(this.labelService.labels[row])})

    let secondaryMetric =["","Secondary Metric"]
    excelData.secondaryMultipleValues.metric.forEach((row, i) => { 
      const label = excelData.secondaryMultipleValues.fy[i] ? 
      excelData.secondaryMultipleValues.fy[i] + " " + this.labelService.labels[row + "_metric"] 
      : ""
      secondaryMetric.push(label ? label : "")
    })

    let secondaryAdjustedMetric = ["","Adjusted Metric"]
    excelData.secondaryMultipleValues.adjustedMetric.forEach((row, i) => { secondaryAdjustedMetric.push(row)})

    // Comp multiple
    let compMultiple = ["","Market Multiple (x)"]
    excelData.secondaryMultipleValues.marketMultiple.forEach((row, i) => { compMultiple.push(row)})

    let secondaryDiscountPremiumToMultiples = ["","Discount/ Premium to Multiples (%)"]
    excelData.secondaryMultipleValues.discountPremium.forEach((row, i) => { secondaryDiscountPremiumToMultiples.push(row)})

    let concludedMultiple = ["","Concluded multiple (x)"]
    excelData.secondaryMultipleValues.concludedMultiple.forEach((row, i) => { concludedMultiple.push(row)})

    let secondaryWeightsToMultiple = ["","Weights to Multiple (%)"]
    excelData.secondaryMultipleValues.weightsToMultiple.forEach((row, i) =>{ secondaryWeightsToMultiple.push(row)})
    
    // valuation Summary

    let algoWeights = [];
    excelData.valuationSummary.algorithms.forEach((algoArray, i) =>{ 
      algoArray.unshift("");
      algoArray[1] = this.ps.displayAlgorithmsName(algoArray[1]) + " (%)"
      algoWeights.push(algoArray);
    })

    let concludedEnterpriseValue = ["","Concluded Enterprise Value"]
    excelData.valuationSummary.concludedEnterpriseValue.forEach((row, i) =>{ concludedEnterpriseValue.push(row)})

    let netDebt = ["","Net Debt"]
    excelData.valuationSummary.netDebt.forEach((row, i) => { netDebt.push(row)})

    let equityValue = ["","Equity Value"]
    excelData.valuationSummary.equityValue.forEach((row, i) => { equityValue.push(row)})

    let stake = ["","Stake (%)"]
    excelData.valuationSummary.stake.forEach((row, i) => { stake.push(row)})

    let fairValue = ["","Fair Value"]
    excelData.valuationSummary.fairValue.forEach((row, i) => { fairValue.push(row)})

    let realisedProceeds = ["","Realised Proceeds"]
    excelData.valuationSummary.equityValue.forEach((row, i) => { realisedProceeds.push(row)})

    let investmentAmount = ["","Investment Amount"]
    excelData.valuationSummary.investmentAmount.forEach((row, i) => { investmentAmount.push(row)})

    // let ytdValueCreation = ["","YTD Value Creation (%)"]
    // excelData.valuationSummary.ytdValueCreation.forEach((row, i) => { ytdValueCreation.push(row)})

    let moic = ["","MOIC (x)"]
    excelData.valuationSummary.moic.forEach((row, i) => { moic.push(row)})

    let irr = ["","IRR (%)"]
    excelData.valuationSummary.grossIRR.forEach((row, i) => { irr.push(row)})


    let headers = [" ", companyName, " " + (moment(latestValuationDate).format("ll"))] 
    values.push(["", "", "", "", "","", "", "", "", ""]);
    values.push(["","Diluted Ownership", excelData.valuationSummary.stake[excelData.valuationSummary.stake.length - 1] + "%"]);
    values.push(["", "", "", "", "","", "", "", "", ""]);
    values.push(valuationDateList);
    values.push(["", "", "", "", "","", "", "", "", ""]);
    values.push(["","Trading Comps Analysis", ]);
    // Primary Multiple
    values.push(primaryMultiple);
    values.push(primaryMetric);
    values.push(adjustedMetric);
    values.push(marketMultiple);
    values.push(discountPremiumToMultiples);
    values.push(netMultiple);
    values.push(weightsToMultiple);

    values.push(["", "", "", "", "","", "", "", "", ""])
    // Secondary Multiple
    values.push(secondaryMultiple);
    values.push(secondaryMetric);
    values.push(secondaryAdjustedMetric);
    values.push(compMultiple);
    values.push(secondaryDiscountPremiumToMultiples);
    values.push(concludedMultiple);
    values.push(secondaryWeightsToMultiple);
    values.push(["", "", "", "", "","", "", "", "", ""])

    values.push(["","Valuation Summary", ]);
    algoWeights.forEach(algo=>{
      values.push(algo);
    })
    values.push(["", "", "", "", "","", "", "", "", ""])
    values.push(concludedEnterpriseValue)
    values.push(netDebt)
    values.push(equityValue)
    values.push(stake)
    values.push(fairValue)
    values.push(realisedProceeds)
    values.push(investmentAmount)
    // values.push(ytdValueCreation)
    values.push(moic)
    values.push(irr)

    values.push(["", "", "", "", "","", "", "", "", ""]);
    let tempValue =[]
    excelData.tradingComps.forEach((row, i) =>{ 
      if(i==0){ 
        tempValue.push("","Comparable Company List")
      }
      else{
        tempValue.push("","")
      }
      row.forEach(cell => {tempValue.push(cell)})
      values.push(tempValue)
      tempValue= []
    })

    return [{ "tabName": "Excel Download", "data": {"headers": headers, "values": values} }]
  }


  downloadExcel(companyId, companyName, latestValuationDate) {
    this.excelDownloading = true;

    let excelData;

    this.dataService.getExcelDataForCompaniesInCheckList(companyId, this.ps.selectedFundId).subscribe(res => {
      excelData = res.body["response"];

      this.prepareExcelDataAndDownload(excelData, companyName, latestValuationDate);

    }, error => {
      this.excelDownloading = false;
      console.log("Data preparation for Excel is failed");
      this.toastService.openSnackBar("Data preparation for Excel is failed. Please try after some time");
    })   
  }

  prepareExcelDataAndDownload(excelData, companyName, latestValuationDate) {

    let fileData = this.getExcelDownloadForApprovalCompanyChecklistV2(excelData, companyName, latestValuationDate);

    this.dataService.downloadExcelFileV2(fileData).subscribe(
      (fileData) => {
        this.manageFileDownload(fileData, "xlsx", companyName, latestValuationDate);

        this.excelDownloading = false;            
      },
      (err) => {
        console.log("Failed to download file", err);

        this.excelDownloading = false;

        this.toastService.openSnackBar("Sorry! Not able to download the file. Please contact us.");
      }
    );
  }

  manageFileDownload(fileData, type, companyName, valuationDate) {
    try {
      const url = (window as any).URL.createObjectURL(fileData.body);
      
      this.downloadLink.nativeElement.href = url;
      this.downloadLink.nativeElement.target = "_blank";
      this.downloadLink.nativeElement.download = 
        companyName +
        "_" +
        "Valuation Summary_" +
        valuationDate +
        "." +
        type;
      this.downloadLink.nativeElement.click();
    } catch (e) {
      console.log("Failed to download file", e);
      this.utilService.showMessage(
        "Sorry! Not able to download the file. Please contact us.",
        "Ok"
      );
    }
  }

  async navigateToAlgo(navigation, comp){
    console.log("key: ", comp, navigation)

      // Load valuation page for user selected valuation date.
      const fundName = await this.ps.getSelectedFundName();

      const navigationKey = "&navigationKey=" + navigation.navigationKey;

      const valuationDateObj = {
        "id": comp.latestValuationDateId,
        "versionName": comp.version,
        "valuationDate": comp.latestValuationDate,
        "fundCompany": this.ps.selectedFundId
      }
      this.utilService.navigateToValuationSummary(valuationDateObj, fundName, comp.companyName, comp.version, navigationKey, "CHECK_LIST_NAVIGATION");
    
  }

   //Ignore if it is user added old valuation date
  //  if(!comp.id || comp.id.indexOf("_S") > 0) return;

  //  //Ignore if it is Consol form (which has business units)
  //  if(comp.businessUnits) return;

  //  const version = 4; //comp.formVersion;

  //  // let versionName=comp.versionName;
  //  let urlParameters = "id=" + comp.id + "&fv=" + version + "&fundName="+fundName + "&versionName="+versionName;

  //  if(comp.consolForm) {
  //    urlParameters += "&consol=" + companyNameInForm;
  //  }

  //  this.ums.addUserAction("Valuation Date Selection", comp.id, comp.companyName+ " | " +comp.valuationDate, sourceOfAction);

  //  let url = environment.portalUrl + environment.pvValuation + "/#/valuation-summary?" + urlParameters + "&parentId=" + comp.fundCompany;

  //  if(comp.approvalRequestId) {
  //    url += "&aid="+comp.approvalRequestId;
  //  }

  //  if(additionalParams) {
  //    url += additionalParams;
  //  }

  openApprovalMatrixPortal(fundId) {
    window.open(environment.portalUrl + "/approval-matrix", '_blank');
  }

  openApprovalMatrixBetaPortal(fundId) {
  window.open(environment.portalUrl + "/approval-mgmt/#/approval-setup?parentId=" + this.ps.selectedFundId + "&moduleType=equity");
  }

  async initiateApprovalRoute(comp) {
    const user = this.ums.getSelectedUserDetails();

    const fundId = this.ps.selectedFundId;

    this.approvalIniationInProgress[comp.companyId] = true;

    try {
      const approvalConditionData = this.approvalConditionData;
    
      let requestObj = this.getRequestBodyForApprovalRoute(approvalConditionData, user.organization.id, comp);

      requestObj.new["approvals"] = [];

      approvalConditionData.approvers.forEach((approver, index) => {
          if(approver.type == "group")
          {
            let groupObj = {
              "group": {
                "id": approver.id,
                "name": approver.name
              },
              "status": "pending",
              "comments": "",
              "timestamp": ""
            }

            requestObj.new["approvals"].push(groupObj);
          }
          else
          {
            let userObj = {
              "user": {
                "id": approver.id,
                "name": approver.name
              },
              "status": "pending",
              "comments": "",
              "timestamp": ""
            }

            requestObj.new["approvals"].push(userObj);
          }
        });  

        if(requestObj.new.conditionId)
        {
          let requestCreationStatus = await this.dataService.initiateApprovalRequest(requestObj);

          if(requestCreationStatus.status == 200)
          {
            comp.approvalRequestId = requestCreationStatus.body["response"].id;
            this.toastService.openSnackBar('Approval Initiated Successfully',);
          }
        }

        this.approvalCompObject = comp;
        this.approvalComment = null;
        this.approveRequest(comp);

    } catch(e) {
      console.log("Failed to initiate approval route", e);
      this.resetApprovalStatus(comp);
    }
  }

  getRequestBodyForApprovalRoute(approvalConditionData, orgId, comp) {
    const objectNameJson={
      "companyName":comp.companyName,
      "valDate":comp.valuationDate,
      "version":comp.versionName
    }
    return {
      new: {
        "id": "",
        "conditionId": approvalConditionData.conditionId,
        "conditionName": approvalConditionData.conditionName,
        "conditionLevel": approvalConditionData.conditionLevel,
        "fundId": this.ps.selectedFundId,
        "firmId": orgId,
        "companyId": comp.companyId,
        "objectLocked": "1",
        "status": "pending",
        "objectName":JSON.stringify(objectNameJson),
        "objectId": comp.latestValuationDateId,
        "objectContent": environment.portalUrl + "/pv/#/valuation-summary?id=" + comp.latestValuationDateId + "&fv=4",
        "objectType": "valuation",
        "sendEmail": false
      }
    }
  }

  getApprovalActionRequestObject(valDateObject, comment, statusInput) {
    this.approvalIniationInProgress[valDateObject.companyId] = true;

    let requestObj = {
        "approvalRequestID": valDateObject.approvalRequestId,
        "userId": this.approvalMatrixService.getLoggedInUserId(),
        "comments": comment,
        "status": statusInput,
        "sendEmail": true
    }

    return requestObj;
  }

  recallApprovalRequest(valDateObject)
  {
    const recallObj = this.getApprovalActionRequestObject(valDateObject, "Recalled", "recalled");

    this.dataService.recallRequest(recallObj).then(() => {
      this.toastService.openSnackBar('Approval is recalled Successfully');
      
      const existingApprovalRouteIndex = this.approvalMatrixService.allApprovalDetails.findIndex(ap => ap.id == valDateObject.approvalRequestId);
      
      if(existingApprovalRouteIndex >= 0) {
        this.approvalMatrixService.allApprovalDetails.splice(existingApprovalRouteIndex, 1);
      }

      valDateObject.approvalRequestId = null;

      this.resetApprovalStatus(valDateObject);
    },
    err =>{
      this.resetApprovalStatus(valDateObject);
    });
  }

  approveRequest(valDateObject)
  {
    this.utilService.showLoadingPopup();
    
    valDateObject = this.approvalCompObject;

    let comment = "Approved";
    if(this.approvalComment && this.approvalComment.length > 0)
    {
      comment = this.approvalComment;
    }

    const approveObj = this.getApprovalActionRequestObject(valDateObject, comment, "approved");

    this.dataService.approveRequest(approveObj).then(() => {
      this.toastService.openSnackBar('Approval route is approved successfully');
      this.resetApprovalStatus(valDateObject);
    },
    err =>{
      this.resetApprovalStatus(valDateObject);
    });    
  }

  rejectRequest(valDateObject)
  {
    this.utilService.showLoadingPopup();

    valDateObject = this.approvalCompObject;
    
    let comment = "Rejected";
    if(this.approvalComment && this.approvalComment.length > 0)
    {
      comment = this.approvalComment;
    }

    const rejectObj = this.getApprovalActionRequestObject(valDateObject, comment, "rejected");
    
    this.dataService.rejectRequest(rejectObj).then(() => {
      this.toastService.openSnackBar('Approval route is rejected successfully');
      this.resetApprovalStatus(valDateObject);
    },
    err =>{
      this.resetApprovalStatus(valDateObject);
    });
  }

  async resetApprovalStatus(comp) {
    const existingApprovalRouteIndex = this.approvalMatrixService.allApprovalDetails.findIndex(ap => ap.id == comp.approvalRequestId);
    
    if(existingApprovalRouteIndex >= 0) {
      this.approvalMatrixService.allApprovalDetails.splice(existingApprovalRouteIndex, 1);
    }

    if(comp.approvalRequestId) {
      //This blcok is executed for all actions except RECALL
      const allApprovalIds = [comp.approvalRequestId];
      const user = this.ums.getSelectedUserDetails();

      const newApprovalRoute = await this.approvalMatrixService.getApprovalDataForAllIds(allApprovalIds, user.organization.id);

      this.approvalMatrixService.allApprovalDetails = this.approvalMatrixService.allApprovalDetails.concat(newApprovalRoute);
    }
    
    const approvalStatus = this.approvalMatrixService.getApprovalStatus(this.approvalMatrixService.allApprovalDetails, this.approvalConditionData, this.initiatorsGroupMembers, comp);

    console.log("After Action " + comp.approval.actionName + ": Approval status for " + comp.companyName, approvalStatus);

    comp.approval = approvalStatus;

    this.approvalMatrixService.$approvalRouteUpdated.next(comp.approval.actionName);

    this.modalService.dismissAll();
    this.utilService.closeAllPopups();

    this.approvalIniationInProgress[comp.companyId] = false;
  }

  openCommentBoxForApproval(comp, popup) {
    this.approvalCompObject = comp;

    this.modalService.open(popup, { centered: true , size: 'sm', backdrop: 'static' });
  }

  selectCondition(e, item){
    item.selectedCondition = this.approvalService.allEnabledConditions.find( c => c.id == e.value);

    this.dataService.updateSelectedApprovalConditionForEachFormId(item.selectedCondition.id, item.latestValuationDateId).subscribe(res=> {
        console.log("Approval condition has been updated")
    }, err =>{
      console.log("Failed to update Approval condition: ", err)
    })
  }

  openPopupModal(content, data){

    this.checkListService.blurBG = true;

    if(!data.selectedCondition || !data.selectedCondition.id || data.selectedCondition.conditionName == "None"){
      return;
    }

    this.modalService.open(content, { centered: true , windowClass: 'modal-xl', size: 'md'});
  }

  async updateApprovalSystemOnAction(data){

    this.utilService.showLoadingPopup();

    this.checkListService.blurBG = false;

    const userDetails = this.ums.getSelectedUserDetails();

    // checking whether the document/version has ever recalled/unlocked in the past
    let actionTypeBasedOnDocHistory: any = null;

    if(data.actionName == 'Initiate'){
      try{
        const res = (await this.dataService.getDocumentRecalledStatus(data.id).toPromise() as any).body.response;
        if(!!res){
          actionTypeBasedOnDocHistory = res.isActionType;
        }
      }catch(err){
        console.log("Failed to get the Document recalled status: ", err);
        actionTypeBasedOnDocHistory = null;
      }
    }

    const reqBody = {
      "module": "EQUITY",
      "orgId": userDetails.organization.id,
      "fundId": this.ps.selectedFundId,
      "companyId": data.companyId,
      "userId": userDetails.id,
      "approvalObjectId": data.latestValuationDateId,
      "comments": data.comment ? data.comment : '',
      "action": data.actionName,
      "conditionId": data.selectedCondition.id,
      "existingApprovalObjectId": !!actionTypeBasedOnDocHistory ? data.id : null,
      "isActionType": actionTypeBasedOnDocHistory
    }

    this.dataService.updateApprovalObject(reqBody).subscribe( async res => {
      console.log("Aprroval has been updated!", res);
      if (data.actionName == "Rejected") {
          this.getSelectedCompanyDetails(data, true, true);
      } else {
        this.getSelectedCompanyDetails(data, true);
      }
    }, error => {
      console.log("Aprroval failed to update: ", error);
      this.utilService.closeAllPopups();
      this.utilService.showMessage(
        "Aprroval failed to update. Please contact us.",
        "Ok"
      );
    })
  }

}
