import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from 'src/app/services/translation.service';
import sortBy from 'lodash/sortBy';

import { UtilService } from 'src/app/utils/util.service';
import * as Rx from "rxjs";
import { DataService } from 'src/app/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class InvestmentSummaryService {

  valuationDateSummaryReady$ = new Rx.BehaviorSubject({});

  allValuationDatesWithVersions;

  allValuationDates;

  allCustomAttributes;

  showSettings = false;
  showTrackRecord = false;

  TYPE_OF_SUBMISSION = {
    REPLICATE : "replicate",
    REVALUE : "revalue",
  }

  investmentDateValuation = {} as any;

  businessUnitsValSummaryForAllIds = {
    summary: {},
    currency: {}
  } as any;

  companyId;

  INVESTMENT_SUMMARY_SAVE = "INVESTMENT_SUMMARY_SAVE";
  INVESTMENT_SUMMARY_SETTINGS_SAVE = "INVESTMENT_SUMMARY_SETTINGS_SAVE";

  updatingTrackRecordOnAttrsUpdate$ = new Subject();

  equityValue = {
    enterpriseValue: 0,
    weightedEquityValue: 0,
    isEVBasedValuationSummary: true,
    surplusAssetsTotalEquityVal: 0,
    dlomAdjustment: 0,
    dlomAdjustedEquityVal: 0,
    dlocAdjustment: 0,
    dlocAdjustedEquityVal: 0,
    finalAdjustedEquityVal: 0,
    stake: 100,
    stakeValue: 0,
    overAllImpliedEV: 0,
    changeInEquityValue: 0,
    changeInPercentageEquityValue: 0
  } as any;
  

  constructor(private utilService : UtilService, private translateService: TranslateService, private ds : DataService) { }

  getToSettingsOptions(){
    setTimeout(() => {
      let settingsView = document.getElementById("is_Settings");
      settingsView.scrollIntoView({behavior: "smooth", block: "center"});
    })
  }

  getTrackRecordTable(){
    setTimeout(() => {
      let settingsView = document.getElementById("is_TrackRecord");
      settingsView.scrollIntoView({behavior: "smooth", block: "start"});
    })
  }

  addInfoToFormobjectFromDetails(valuationDates){
    let updatedValuationDates = []
    
    if(valuationDates && valuationDates.length > 0){
      updatedValuationDates = valuationDates.map(form => {
        const details = JSON.parse(form.details);
  
        if(details) {           
          if(!form.companyName) {
            form.companyName = this.utilService.cleanString(details["companyName"]);
            form.companyNameInForm = this.utilService.cleanString(details["companyNameInForm"]);
  
          } else {
            form.companyNameInForm = form.companyName;
          }
  
          form.currency = details["currency"];
          form.status = details["status"];
          form.website = details["website"];
          form.geography = details["geography"];
          form.versionName = details["versionName"];
          form.businessUnitsNumber = details["businessUnitsNumber"];
          form.createdUser = details["vcName"];
          form.createdByName = details["createdByName"] ? details["createdByName"] : details["vcName"];
  
          if(details["sector"] && typeof details["sector"] == "string") {
            const sectorParts = details["sector"].split(",")
            form.sector = sectorParts[0];
          }

          if(details["businessUnitName"]){
            if(!form.companyName) {
              form.companyName = this.utilService.cleanString(details["companyName"]);
              form.companyNameInForm = this.utilService.cleanString(details["companyNameInForm"]);
    
            } else {
              form.companyName = this.utilService.cleanString(details["companyName"]);
              form.companyNameInForm = this.utilService.cleanString(details["companyNameInForm"]);
            }
            
            form.businessUnitName = details["businessUnitName"];
          }
        }
  
        return form;
      })
    }

    return updatedValuationDates;
  }

  initBU_ConsolSummary(latestCompanyForm) {    
    const enterpriseValue = this.getBusinessUnitParameterValue2("enterpriseValue", latestCompanyForm);
    const investment = {
      currency : latestCompanyForm.currency,
      enterpriseValue: enterpriseValue,

      equityValue: {
        weightedEquityValue: enterpriseValue,
        finalAdjustedEquityVal: enterpriseValue,
        stake: 100,
        finalStakeValue: enterpriseValue,
        stakeValue: enterpriseValue,
      }
    }

    let buValuationSummary = {
      currency:latestCompanyForm.investment && latestCompanyForm.investment.currency,
      sumOfTheParts: enterpriseValue,
      sumOfPartsAdj: null,
      weightedEquityValue: enterpriseValue,
      adjustmentsOnWeightedEquityValue: null,

      selectedTypeOfStakeValue: 'fullyDilutedShares',

      finalAdjustedEquityVal: enterpriseValue,
      stake: 100,
      stakeValue: enterpriseValue,
      finalAdjustments: null,
      finalStakeValue: enterpriseValue,
      numberOfShares: 0,
      sharePrice: 0,
      sharePriceFormatted: 0,
  
      customStake: {
        stakeFromModel : { value: 0 },
        manualEntry : { value: 100 }, 
        selectedOption: "manualEntry",
        dilutedStake: {value:100},
        impliedStakeWaterfall: {value:100},
        impliedStakeManpack: {value:100, exists: false},
        impliedStakeManOption: {value:100, exists: false},
      }
    }

    buValuationSummary = this.businessUnitsValSummaryForAllIds.summary[latestCompanyForm.id];

    investment.equityValue.stake = buValuationSummary.stake;

    const equityAdjustments = buValuationSummary.sumOfPartsAdj;
    if(equityAdjustments) {
      const weightedEquityValue = this.getConsolAdjustedValue(investment.enterpriseValue, equityAdjustments);
      investment.equityValue.weightedEquityValue = weightedEquityValue;
      investment.equityValue.finalAdjustedEquityVal = weightedEquityValue;
    }

    const adjustmentsOnWeightedEquityValue = buValuationSummary.adjustmentsOnWeightedEquityValue;
    if(adjustmentsOnWeightedEquityValue) {
      investment.equityValue.finalAdjustedEquityVal = this.getConsolAdjustedValue(investment.equityValue.weightedEquityValue, adjustmentsOnWeightedEquityValue);
    }
    
    if(buValuationSummary.selectedTypeOfStakeValue == "fromWaterFallSummary") {
      // In case of `Waterfall Summary`, the stake value comes from waterfall model
      investment.equityValue.stakeValue = buValuationSummary.stakeValue;

    } else {

      const stakeValue = investment.equityValue.finalAdjustedEquityVal
          * investment.equityValue.stake
          / 100;

      investment.equityValue.stakeValue = stakeValue;
    }

    investment.equityValue.finalStakeValue = investment.equityValue.stakeValue;

    const finalAdjustments = buValuationSummary.finalAdjustments;
    if(finalAdjustments) {
      investment.equityValue.finalStakeValue = this.getConsolAdjustedValue(investment.equityValue.stakeValue, finalAdjustments);
    } 

    if(buValuationSummary.sumOfTheParts == 0) {    
      investment.equityValue.finalAdjustedEquityVal = 0;
      investment.equityValue.stakeValue = 0;
      investment.equityValue.finalStakeValue = 0;
    }

    latestCompanyForm.investment = investment;
    
    buValuationSummary.weightedEquityValue = investment.equityValue.weightedEquityValue;
    buValuationSummary.finalAdjustedEquityVal = investment.equityValue.finalAdjustedEquityVal;
    buValuationSummary.stakeValue = investment.equityValue.stakeValue;
    buValuationSummary.finalStakeValue = investment.equityValue.finalStakeValue;

    if(buValuationSummary.numberOfShares > 0) {
      buValuationSummary.sharePrice = (buValuationSummary.finalStakeValue * 1000000) / buValuationSummary.numberOfShares;
    }

    this.businessUnitsValSummaryForAllIds.summary[latestCompanyForm.id] = cloneDeep(buValuationSummary);
  }

  getConsolAdjustedValue(startValue, adjustments) {
    let adjustedValue = startValue;

    if(adjustments) {
      adjustments.forEach(part => {
        if (part.type == "Add") {
          adjustedValue = adjustedValue + (part.convertedValue || part.value)
        }
        else {
          adjustedValue = adjustedValue - (part.convertedValue || part.value)
        }
      });
    }

    return adjustedValue;
  }

  getBusinessUnitParameterValue2(parameter, consolForm) {
    let total = 0
    
    consolForm.businessUnits.forEach( (bu) => {
      if(bu.investment) {
        const currencyCode = bu.investment.currency + consolForm.investment.currency + consolForm.valuationDate;
        // Example currency code USDEUR2021-03-31

        let exchnageRate = this.businessUnitsValSummaryForAllIds.currency[currencyCode];

        if(!exchnageRate) {
          exchnageRate = 1;
        }

        total += this.utilService.getValidNumber(bu.investment[parameter]) * exchnageRate;
      }
    })

    return total;
  }

  checkWhetherBusinessUnitIsSubmitted(form){
    let isSubmitted = form.status == 'Submitted' ? true : false;

    if(form.businessUnits && form.businessUnits.length > 0){
      let buSubmittedStatus = false;
      form.businessUnits.forEach(bu => {
        buSubmittedStatus = buSubmittedStatus || bu.status == "Submitted";
      })

      isSubmitted = buSubmittedStatus;
    }

    return isSubmitted;
  }

  getMarketMultipleLabelForEvolutionChartByKey(key){
    switch(key) {
      case "bevRevenue" : return this.translateService.getLabel("ev") + " / " +  this.translateService.getLabel("revenue")
      case "bevEbitda" : return this.translateService.getLabel("ev") + " / " +  this.translateService.getLabel("ebitda")
      case "bevEbit" : return this.translateService.getLabel("ev") + " / " +  this.translateService.getLabel("ebit")
      case "bevEbitda_Capex" : return this.translateService.getLabel("ev") + " / " +  this.translateService.getLabel("ebitda-capex")
      case "evGrossProfit" : return this.translateService.getLabel("ev") + " / " +  this.translateService.getLabel("gross_profit")
      
      case "psales" : return this.translateService.getLabel("price") + " / " +  this.translateService.getLabel("sales")
      case "pe" : return this.translateService.getLabel("price") + " / " +  this.translateService.getLabel("earnings")
      case "pbv" : return this.translateService.getLabel("price") + " / " +  this.translateService.getLabel("book_value") 
    }

    return key

  }

  updateSummaryAndValuationDateAttributesByCompany(companyId, fundId, source){
    const reqBody = {
      companyId : companyId,
      fundId  : fundId,
      source  : source
    }
    this.ds.updateSummaryAndValuationDateAttributes(reqBody).subscribe(res => {
      console.log("Sucessfully updated the Summary and valuation Date Attributes", res);
      this.updatingTrackRecordOnAttrsUpdate$.next();
    }, error => {
      console.log("Failed to updated the Summary and valuation Date Attributes", error);
    })
  }

  getTrackRecordMetricAndPeriod(settings){
    // Revenue as primary Metric
    if(settings.metric == 'bevRevenue'){
      if(settings.metric == 'bevRevenue' && settings.secondaryMetric == 'bevRevenue' 
          && settings.period !=  settings.secondaryPeriod){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'bevRevenue',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod 
        }
      }
      if(settings.metric == 'bevRevenue' && settings.secondaryMetric == 'bevRevenue'){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'bevEbitda',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod 
        }
      }
      if(settings.metric == 'bevRevenue' && settings.secondaryMetric == 'evGrossProfit'){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'evGrossProfit',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod 
        }
      }
      if(settings.metric == 'bevRevenue' && settings.secondaryMetric == 'bevEbitda'){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'bevEbitda',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod 
        }
      }
      if(settings.metric == 'bevRevenue' && settings.secondaryMetric == 'bevEbitda_Capex'){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'bevEbitda_Capex',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod 
        }
      }
      if(settings.metric == 'bevRevenue' && settings.secondaryMetric == 'pbv'){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'pbv',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod 
        }
      }
      if(settings.metric == 'bevRevenue' && settings.secondaryMetric == 'bevEbit'){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'bevEbit',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod 
        }
      }
      if(settings.metric == 'bevRevenue' && settings.secondaryMetric == 'pe' || settings.secondaryMetric == 'priceEarnings'){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'pe',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod 
        }
      }
      else{
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'evGrossProfit',
          "thirdMetric": settings.secondaryMetric,
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
    }

    // Gross Price as primaryMetric
    if(settings.metric == 'evGrossProfit'){
      if(settings.metric == 'evGrossProfit' && settings.secondaryMetric == 'bevRevenue'){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'evGrossProfit',
          "firstPeriod": settings.secondaryPeriod,
          "secondaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'evGrossProfit' && settings.secondaryMetric == 'evGrossProfit' && settings.period ==  settings.secondaryPeriod){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'evGrossProfit',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'evGrossProfit' && settings.secondaryMetric == 'bevEbitda'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'evGrossProfit',
          "thirdMetric": 'bevEbitda',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
      if(settings.metric == 'evGrossProfit' && settings.secondaryMetric == 'bevEbitda_Capex'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'evGrossProfit',
          "thirdMetric": 'bevEbitda_Capex',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
      if(settings.metric == 'evGrossProfit' && settings.secondaryMetric == 'bevEbit'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'evGrossProfit',
          "thirdMetric": 'bevEbit',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
      if(settings.metric == 'evGrossProfit' && settings.secondaryMetric == 'pe' || settings.secondaryMetric == 'priceEarnings'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'evGrossProfit',
          "thirdMetric": 'pe',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
      else{
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": settings.metric,
          "thirdMetric": settings.secondaryMetric,
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
    }

    // Ebitda as primary Metric
    if(settings.metric == 'bevEbitda'){
      if(settings.metric == 'bevEbitda' && settings.secondaryMetric == 'bevRevenue'){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'bevEbitda',
          "firstPeriod": settings.secondaryPeriod,
          "secondaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'bevEbitda' && settings.secondaryMetric == 'evGrossProfit'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'evGrossProfit',
          "thirdMetric": 'bevEbitda',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod, 
          "tertiaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'bevEbitda' && settings.secondaryMetric == 'bevEbitda'  && settings.period ==  settings.secondaryPeriod){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'bevEbitda',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'bevEbitda' && settings.secondaryMetric == 'bevEbitda_Capex'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'bevEbitda',
          "thirdMetric": 'bevEbitda_Capex',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
      if(settings.metric == 'bevEbitda' && settings.secondaryMetric == 'bevEbit'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'bevEbitda',
          "thirdMetric": 'bevEbit',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
      if(settings.metric == 'bevEbitda' && settings.secondaryMetric == 'pe' || settings.secondaryMetric == 'priceEarnings'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'bevEbitda',
          "thirdMetric": 'pe',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
      else{
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": settings.metric,
          "thirdMetric": settings.secondaryMetric,
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
    }

    // Ebitda-Capex as primary Metric 
    if(settings.metric == 'bevEbitda_Capex'){
      if(settings.metric == 'bevEbitda_Capex' && settings.secondaryMetric == 'bevRevenue'){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'bevEbitda_Capex',
          "firstPeriod": settings.secondaryPeriod,
          "secondaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'bevEbitda_Capex' && settings.secondaryMetric == 'evGrossProfit'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'evGrossProfit',
          "thirdMetric": 'bevEbitda_Capex',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod, 
          "tertiaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'bevEbitda_Capex' && settings.secondaryMetric == 'bevEbitda'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'bevEbitda',
          "thirdMetric": 'bevEbitda_Capex',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod, 
          "tertiaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'bevEbitda_Capex' && settings.secondaryMetric == 'bevEbitda_Capex'  
      && settings.period ==  settings.secondaryPeriod){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'bevEbitda_Capex',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'bevEbitda_Capex' && settings.secondaryMetric == 'bevEbit'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'bevEbitda_Capex',
          "thirdMetric": 'bevEbit',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
      if(settings.metric == 'bevEbitda_Capex' && settings.secondaryMetric == 'pe'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'bevEbitda_Capex',
          "thirdMetric": 'pe',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
      else{
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": settings.metric,
          "thirdMetric": settings.secondaryMetric,
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
    }

    // Ebit as primary Metric
    if(settings.metric == 'bevEbit'){
      if(settings.metric == 'bevEbit' && settings.secondaryMetric == 'bevRevenue'){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'bevEbit',
          "firstPeriod": settings.secondaryPeriod,
          "secondaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'bevEbit' && settings.secondaryMetric == 'evGrossProfit'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'evGrossProfit',
          "thirdMetric": 'bevEbit',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod, 
          "tertiaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'bevEbit' && settings.secondaryMetric == 'bevEbitda'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'bevEbitda',
          "thirdMetric": 'bevEbit',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod, 
          "tertiaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'bevEbit' && settings.secondaryMetric == 'bevEbitda_Capex'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'bevEbitda_Capex',
          "thirdMetric": 'bevEbit',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod, 
          "tertiaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'bevEbit' && settings.secondaryMetric == 'bevEbit'  
        && settings.period ==  settings.secondaryPeriod){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'bevEbit',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'bevEbit' && settings.secondaryMetric == 'pe'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'bevEbit',
          "thirdMetric": 'pe',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
      else{
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": settings.metric,
          "thirdMetric": settings.secondaryMetric,
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
    }

    // Net Income as primary Metric
    if(settings.metric == 'pe'){
      if(settings.metric == 'pe' && settings.secondaryMetric == 'bevRevenue'){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'pe',
          "firstPeriod": settings.secondaryPeriod,
          "secondaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'pe' && settings.secondaryMetric == 'evGrossProfit'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'evGrossProfit',
          "thirdMetric": 'pe',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod, 
          "tertiaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'pe' && settings.secondaryMetric == 'bevEbitda'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'bevEbitda',
          "thirdMetric": 'pe',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod, 
          "tertiaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'pe' && settings.secondaryMetric == 'bevEbitda_Capex'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'bevEbitda_Capex',
          "thirdMetric": 'pe',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod, 
          "tertiaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'pe' && settings.secondaryMetric == 'bevEbit'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'bevEbit',
          "thirdMetric": 'pe',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod, 
          "tertiaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'pe' && settings.secondaryMetric == 'pe'  
        && settings.period ==  settings.secondaryPeriod){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'pe',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'pe' && settings.secondaryMetric == 'pbv'){
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": 'pe',
          "thirdMetric": 'pbv',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
      else{
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": settings.metric,
          "thirdMetric": settings.secondaryMetric,
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
    }

    //  Shareholder's Equity as primary Metric
     if(settings.metric == 'pbv'){
      if(settings.metric == 'pbv' && settings.secondaryMetric == 'bevRevenue'){
        return {
          "firstMetric": 'bevRevenue',
          "secondMetric": 'pbv',
          "firstPeriod": settings.secondaryPeriod,
          "secondaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'pbv' && settings.secondaryMetric == 'pe'){
        return { 
          "firstMetric":'bevRevenue',
          "secondMetric": 'pe',
          "thirdMetric": 'pbv',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.secondaryPeriod, 
          "tertiaryPeriod": settings.period, 
        }
      }
      if(settings.metric == 'pbv' && settings.secondaryMetric == 'pbv'  
        && settings.period ==  settings.secondaryPeriod){
        return { 
          "firstMetric":'bevRevenue',
          "secondMetric": 'pbv',
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
        }
      }
      else{
        return {
          "firstMetric":'bevRevenue',
          "secondMetric": settings.metric,
          "thirdMetric": settings.secondaryMetric,
          "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
        }
      }
    }
    else if(settings.metric != 'bevRevenue' && settings.metric == settings.secondaryMetric && settings.period !=  settings.secondaryPeriod){
      return {
        "firstMetric": 'bevRevenue',
        "secondMetric": settings.metric,
        "thirdMetric": settings.secondaryMetric,
        "firstPeriod": settings.period,
          "secondaryPeriod": settings.period, 
          "tertiaryPeriod": settings.secondaryPeriod, 
      }
    }
  }

  sortBusinessUnitsByBusinessUnitNameOrderByASC(businessUnits){
    
    if(businessUnits && businessUnits.length > 0){
      businessUnits = businessUnits.map(form => {
        const details = JSON.parse(form.details);
  
        if(details) {           
         if(details["businessUnitName"]){
            if(!form.companyName) {
              form.companyName = this.utilService.cleanString(details["companyName"]);
              form.companyNameInForm = this.utilService.cleanString(details["companyNameInForm"]);
    
            } else {
              form.companyName = this.utilService.cleanString(details["companyName"]);
              form.companyNameInForm = this.utilService.cleanString(details["companyNameInForm"]);
            }
            
            form.businessUnitName = details["businessUnitName"];
          }
        }
  
        return form;
      });

      businessUnits = sortBy(businessUnits, (form) => {
        return form.businessUnitName && form.businessUnitName && form.businessUnitName.toUpperCase();
      });
    }

    return businessUnits;
  }
}
