<ng-template [ngIf]="checkListService.dataLoaded">


  <div class="row" style=" background-color: white; margin: 15px 0px 15px 0px; text-align: left;">
      <div class="col-5" style="padding: 2px 0 0 0; max-width: 26%;">
          <span style="font-size: 13px;"> 
          My Approvals
          </span>
      </div>
      <div class="col-7" style="padding-left: 0;">
          <mat-slide-toggle [(ngModel)]="showMyApprovalsToggle" (change)="onMyApprovalsToggle()"></mat-slide-toggle>
      </div>
  </div>

  <mat-accordion>
      <ng-template ngFor [ngForOf]="companylistForCheckList" let-comp>
          <mat-expansion-panel [expanded]="comp.expanded" *ngIf="showAllApprovals || (showMyApprovalsToggle && comp.pendingAprroval)" (opened)="getSelectedCompanyDetails(comp, true)">
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <div class="row" style="width: 100%; min-height: 4vh; padding-top: 2px; padding-left: 4px;">
                          <div class="col-2" style="padding-right: 0px; padding-left: 15px">
                              <img width=20 height=20 [src]="comp.logo" *ngIf="comp.logo; else defaultLogo">
                              <ng-template #defaultLogo>
                                  <img width=20 height=20 [src]="loaderService.getImage('company_logo.png')"
                                      alt="company-logo">
                              </ng-template>
                          </div>
                          <div class="col" style="padding: 0px;">
                              <span class="blue-font"><b>{{comp.companyName}}</b></span>
                          </div>
                      </div>
                  </mat-panel-title>
              </mat-expansion-panel-header>

              <div style="text-align: right; margin-right: -10px;">                
                  <span class="blue-font anchor-link" *ngIf="!!isApprovalAccessible" (click)="openApprovalMatrixBetaPortal()">
                      <u>Go to Approval System</u>
                  </span>
              </div>

              <div class="row" style="margin-top: 5px;">
                  <div class="col-5" style="padding: 0px;">
                      <span class="font-style">{{comp.latestValuationDate | date : "mediumDate"}}</span>
                  </div>
                  
                  <div class="col" style="padding: 0; text-align: right;">                    
                      <span class="version-name-pill">{{comp.version}}</span>
                  </div>
              </div>

              <ng-template ngFor [ngForOf]="comp.options" let-row let-index>
                  <div class="row" style="margin-top: 10px;">
                      <div class="col-12">
                          <mat-checkbox class="custom-frame" [(ngModel)]="row.checked" [checked]="row.checked" (change)=" optionSelect(row, comp)">
                          </mat-checkbox>
                          <span class="blue-font nav-label" (click)="navigateToAlgo(row, comp)">
                              {{row.label}}
                          </span>
                      </div>
                  </div>
                  <hr style="border-top: 1px dotted grey; margin: 0px 12px 0px 20px;">
              </ng-template>

              <ng-template [ngIf]="router.url.includes('company-valuation') && comp.companyId === invSummaryService.companyId">
                <app-download-report-ui [subjectCompany]="comp"></app-download-report-ui>
              </ng-template>

              <div class="row" style="margin-top: 10px;">
                  <div class="col-1" style="padding: 0; padding-left: 15px;">
                      <i class="fa fa-download" [matTooltip]="translateService.getLabel('download_val_summary_excel')" 
                          style="top: calc(50% - 5px); position: relative;"
                          (click)="downloadExcel(comp.companyId, comp.companyName, comp.latestValuationDate)" 
                          aria-hidden="true"  [ngClass]="{'decreaseOpacity': excelDownloading}"></i>
                  </div>
                  <ng-template [ngIf]="!comp.approvalSystem && approvalService.allEnabledConditions.length > 1">
                      <div class="col-7" style="margin-top: 7px; padding-right: 0;">
                          <mat-form-field class="select-condition approval-items">
                                  <mat-select disableOptionCentering panelClass="myPanelClass" [placeholder]="translateService.getLabel('select_condition')" [value]="comp.selectedCondition.id" (selectionChange)="selectCondition($event, comp)">
                                      <mat-option style="padding-right: 0;" *ngFor="let condition of approvalService.allEnabledConditions" [value]="condition.id">
                                          <div class="row">
                                              <div class="col align-left" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" *ngIf="condition.conditionName != null">
                                                <em *ngIf="condition.conditionName != 'None'" class="fa fa-info-circle info-icon" customToolTip [contentTemplate]="showTooltipDropdown">
                                                </em>
                                                <span style="padding-left: 3px;">{{condition.conditionName}}</span>
                                              </div>
                                          </div>
                                          <ng-template #showTooltipDropdown>  
                                              <div style="display: flex; flex-direction: column">
                                                <div class="tooltip-approver" style="padding-bottom: 8px;">{{condition.conditionName}}</div>
                                                <div class="condition-desc">{{condition.description}}</div>
                                                <ng-template ngFor [ngForOf]="condition.approverDetails" let-details let-i="index">
                                                  <div style="padding-bottom: 2px;">
                                                    <span class="tooltip-approver">Approver {{i+1}}: </span>
                                                    <ng-template ngFor [ngForOf]="details.approverDetailsPerLevel" let-approver let-l="index">
                                                      <span *ngIf="(details.approverDetailsPerLevel.length - 1) != l" [ngClass]="{'tooltip-userName' : approver.type == 'user', 'tooltip-groupName' : approver.type == 'group'}"> {{approver.name}},</span>
                                                      <span *ngIf="(details.approverDetailsPerLevel.length - 1) == l" [ngClass]="{'tooltip-userName' : approver.type == 'user', 'tooltip-groupName' : approver.type == 'group'}"> {{approver.name}}</span>
                                                    </ng-template>
                                                  </div>
                                                </ng-template>
                                              </div>
                                          </ng-template>
                                      </mat-option>
                                  </mat-select>
                          </mat-form-field>                    
                      </div>

                      <div class="col-4" style="padding: 0; margin-top: 7px; text-align: right;" *ngIf="!comp.approvalSystem">
                          <button mat-button class="primary-bg approval-items initiate-btn" [ngClass]="{'button-disable': (!comp.selectedCondition.id || comp.selectedCondition.conditionName == 'None'),'button-enable': (!!comp.selectedCondition.id && comp.selectedCondition.conditionName != 'None')}" 
                          (click)="comp.actionName = 'Initiate'; comp.comment = ''; openPopupModal(captureComment, comp);" >
                              {{ translateService.getLabel("initiate_approval") }}
                          </button>
                      </div>
                  </ng-template>

                  <ng-template [ngIf]=" !!comp.approvalSystem && (comp.approvalSystem.approvalObjectStatus == 'APPROVAL_IN_PROGRESS' || comp.approvalSystem.approvalObjectStatus == 'INITIATED') && comp.approvalSystem.status == 'INITIATED' && comp.approvalSystem.userRole == 'INITIATOR'">
                      <div class="col-7" style="margin-top: 7px; text-align: right; padding-right: 0;">
                          <span mat-button [matMenuTriggerFor]="belowMenu" style="margin-left:25%" (menuClosed)="showComment(comp)">
                              <i *ngIf="!comp.showComment" class="far fa-comment-alt comment-icon" (click)="comp.showComment = true"></i>
                              <i *ngIf="comp.showComment" class="fas fa-comment-alt comment-icon"></i>
                            </span>
                            <mat-menu id="visibilityContainer" #belowMenu="matMenu" xPosition="before" yPosition="above">
                              <div class="comment-box">
                                <div class="comment-Body" *ngFor="let detail of comp.approvalUserData">
                                  <div class="row comment-font" style="padding-bottom: 0 !important;">
                                    <div class="col-6 align-left" style="font-weight: bold;">{{detail?.approverName}}</div>
                                    <div class="col-6 align-right" [ngClass]="{'red': detail?.approverStatus == 'REJECTED', 'green': detail?.approverStatus == 'APPROVED', 'yellow': detail?.approverStatus == 'INITIATED'}">
                                      {{detail?.approverStatus | titlecase}}
                                    </div>
                                  </div>
                                  <div class="row font-size-small">
                                    <div class="col-6 align-left" >
                                      <span *ngIf="!detail.approverLevel">Initiator</span>
                                      <span *ngIf="!!detail.approverLevel">Approver Level {{detail?.approverLevel}}</span>
                                    </div>
                                    <div class="col-6 align-right" >{{detail?.approveAt | date:'medium'}}</div>
                                  </div>
                                  <div class="row" style="font-size: 12px; padding-top: 5px !important;">
                                    <div class="col">{{detail?.approverComment}}</div>
                                  </div>
                                </div>
                              </div>
                            </mat-menu>
                      </div>
                      <div class="col-4" style="margin-top: 7px; text-align: right; padding-right: 0;">
                          <button style="border: none; color: #1D1563; background-color: #F1F5FE; border-radius: 6px; cursor: pointer; width: 100%;"
                          (click)="comp.actionName = 'Recall'; comp.comment = 'Recalled'; updateApprovalSystemOnAction(comp)"
                          class="btn-status">
                          {{ translateService.getLabel("recall") }}
                          </button>
                      </div>
                  </ng-template>

                  <ng-template [ngIf]="!!comp.approvalSystem && (comp.approvalSystem.status != 'APPROVED' && comp.approvalSystem.status != 'REJECTED')  && comp.approvalSystem.userRole == 'APPROVER' && (comp.approvalSystem.approvalObjectStatus == 'APPROVAL_IN_PROGRESS' || comp.approvalSystem.approvalObjectStatus == 'INITIATED')">
                      <div class="col-4" style="margin-top: 7px; text-align: right; padding-right: 0;">
                          <span mat-button [matMenuTriggerFor]="belowMenu" style="margin-left:25%" (menuClosed)="showComment(comp)">
                              <i *ngIf="!comp.showComment" class="far fa-comment-alt comment-icon" (click)="comp.showComment = true"></i>
                              <i *ngIf="comp.showComment" class="fas fa-comment-alt comment-icon"></i>
                            </span>
                            <mat-menu id="visibilityContainer" #belowMenu="matMenu" xPosition="before" yPosition="above">
                              <div class="comment-box">
                                <div class="comment-Body" *ngFor="let detail of comp.approvalUserData">
                                  <div class="row comment-font" style="padding-bottom: 0 !important;">
                                    <div class="col-6 align-left" style="font-weight: bold;">{{detail?.approverName}}</div>
                                    <div class="col-6 align-right" [ngClass]="{'red': detail?.approverStatus == 'REJECTED', 'green': detail?.approverStatus == 'APPROVED', 'yellow': detail?.approverStatus == 'INITIATED'}">
                                      {{detail?.approverStatus | titlecase}}
                                    </div>
                                  </div>
                                  <div class="row font-size-small">
                                    <div class="col-6 align-left" >
                                      <span *ngIf="!detail.approverLevel">Initiator</span>
                                      <span *ngIf="!!detail.approverLevel">Approver Level {{detail?.approverLevel}}</span>
                                    </div>
                                    <div class="col-6 align-right" >{{detail?.approveAt | date:'medium'}}</div>
                                  </div>
                                  <div class="row" style="font-size: 12px; padding-top: 5px !important;">
                                    <div class="col">{{detail?.approverComment}}</div>
                                  </div>
                                </div>
                              </div>
                            </mat-menu>
                      </div>

                      <div class="col-7" style="margin-top: 7px; padding-right: 0;">
                          <button style="border: none; color: #45C94E; background-color: #45C94E1A; border-radius: 6px 0 0 6px; cursor: pointer; width: 50%;"
                          (click)="comp.actionName = 'Approved'; comp.comment = ''; openPopupModal(captureComment, comp)"
                          class="btn-status">
                              {{ translateService.getLabel("approve") }}
                          </button>
                          <button style="border: none; color: #EE0F0F; background-color: #ff4c4c1a; border-radius: 0 6px 6px 0; cursor: pointer; width: 50%;"
                          (click)="comp.actionName = 'Rejected'; comp.comment = ''; openPopupModal(captureComment, comp)"
                          class="btn-status" >
                              {{ translateService.getLabel("reject") }}
                          </button>
                      </div>
                  </ng-template>

                  <ng-template [ngIf]="!!comp.approvalSystem && (comp.approvalSystem.approvalObjectStatus == 'INITIATED' || comp.approvalSystem.approvalObjectStatus == 'APPROVAL_IN_PROGRESS') && (!comp.approvalSystem.userRole || (comp.approvalSystem.status == 'INITIATED' && !comp.approvalSystem.userRole) || comp.approvalSystem.status == 'APPROVED')">
                      <div class="col-4" style="margin-top: 7px; text-align: right; padding-right: 0;">
                          <span mat-button [matMenuTriggerFor]="belowMenu" style="margin-left:25%" (menuClosed)="showComment(comp)">
                              <i *ngIf="!comp.showComment" class="far fa-comment-alt comment-icon" (click)="comp.showComment = true"></i>
                              <i *ngIf="comp.showComment" class="fas fa-comment-alt comment-icon"></i>
                            </span>
                            <mat-menu id="visibilityContainer" #belowMenu="matMenu" xPosition="before" yPosition="above">
                              <div class="comment-box">
                                <div class="comment-Body" *ngFor="let detail of comp.approvalUserData">
                                  <div class="row comment-font" style="padding-bottom: 0 !important;">
                                    <div class="col-6 align-left" style="font-weight: bold;">{{detail?.approverName}}</div>
                                    <div class="col-6 align-right" [ngClass]="{'red': detail?.approverStatus == 'REJECTED', 'green': detail?.approverStatus == 'APPROVED', 'yellow': detail?.approverStatus == 'INITIATED'}">
                                      {{detail?.approverStatus | titlecase}}
                                    </div>
                                  </div>
                                  <div class="row font-size-small">
                                    <div class="col-6 align-left" >
                                      <span *ngIf="!detail.approverLevel">Initiator</span>
                                      <span *ngIf="!!detail.approverLevel">Approver Level {{detail?.approverLevel}}</span>
                                    </div>
                                    <div class="col-6 align-right" >{{detail?.approveAt | date:'medium'}}</div>
                                  </div>
                                  <div class="row" style="font-size: 12px; padding-top: 5px !important;">
                                    <div class="col">{{detail?.approverComment}}</div>
                                  </div>
                                </div>
                              </div>
                            </mat-menu>
                      </div>
                      <div class="col-7" style="margin-top: 10px; text-align: right;">
                          <span style="color: #45C94E;">
                              {{ translateService.getLabel("approval") }} - {{ translateService.getLabel("in_progress") }}
                          </span>
                      </div>
                  </ng-template>

                  <ng-template [ngIf]="!!comp.approvalSystem && comp.approvalSystem.approvalObjectStatus == 'REJECTED'">
                      <div class="col-7" style="margin-top: 7px; text-align: right; padding-right: 0;">
                          <span mat-button [matMenuTriggerFor]="belowMenu" style="margin-left:25%" (menuClosed)="showComment(comp)">
                              <i *ngIf="!comp.showComment" class="far fa-comment-alt comment-icon" (click)="comp.showComment = true"></i>
                              <i *ngIf="comp.showComment" class="fas fa-comment-alt comment-icon"></i>
                            </span>
                            <mat-menu id="visibilityContainer" #belowMenu="matMenu" xPosition="before" yPosition="above">
                              <div class="comment-box">
                                <div class="comment-Body" *ngFor="let detail of comp.approvalUserData">
                                  <div class="row comment-font" style="padding-bottom: 0 !important;">
                                    <div class="col-6 align-left" style="font-weight: bold;">{{detail?.approverName}}</div>
                                    <div class="col-6 align-right" [ngClass]="{'red': detail?.approverStatus == 'REJECTED', 'green': detail?.approverStatus == 'APPROVED', 'yellow': detail?.approverStatus == 'INITIATED'}">
                                      {{detail?.approverStatus | titlecase}}
                                    </div>
                                  </div>
                                  <div class="row font-size-small">
                                    <div class="col-6 align-left" >
                                      <span *ngIf="!detail.approverLevel">Initiator</span>
                                      <span *ngIf="!!detail.approverLevel">Approver Level {{detail?.approverLevel}}</span>
                                    </div>
                                    <div class="col-6 align-right" >{{detail?.approveAt | date:'medium'}}</div>
                                  </div>
                                  <div class="row" style="font-size: 12px; padding-top: 5px !important;">
                                    <div class="col">{{detail?.approverComment}}</div>
                                  </div>
                                </div>
                              </div>
                            </mat-menu>
                      </div>
                      <div class="col-4" style="margin-top: 10px; text-align: right;">
                          <span style="color: #EE0F0F;">
                              {{ translateService.getLabel("rejected") }}
                          </span>
                      </div>
                  </ng-template>

                  <ng-template [ngIf]="!!comp.approvalSystem && comp.approvalSystem.approvalObjectStatus == 'APPROVED'">
                      <div class="col-7" style="margin-top: 7px; text-align: right; padding-right: 0;">
                          <span mat-button [matMenuTriggerFor]="belowMenu" style="margin-left:25%" (menuClosed)="showComment(comp)">
                              <i *ngIf="!comp.showComment" class="far fa-comment-alt comment-icon" (click)="comp.showComment = true"></i>
                              <i *ngIf="comp.showComment" class="fas fa-comment-alt comment-icon"></i>
                            </span>
                            <mat-menu id="visibilityContainer" #belowMenu="matMenu" xPosition="before" yPosition="above">
                              <div class="comment-box">
                                <div class="comment-Body" *ngFor="let detail of comp.approvalUserData">
                                  <div class="row comment-font" style="padding-bottom: 0 !important;">
                                    <div class="col-6 align-left" style="font-weight: bold;">{{detail?.approverName}}</div>
                                    <div class="col-6 align-right" [ngClass]="{'red': detail?.approverStatus == 'REJECTED', 'green': detail?.approverStatus == 'APPROVED', 'yellow': detail?.approverStatus == 'INITIATED'}">
                                      {{detail?.approverStatus | titlecase}}
                                    </div>
                                  </div>
                                  <div class="row font-size-small">
                                    <div class="col-6 align-left" >
                                      <span *ngIf="!detail.approverLevel">Initiator</span>
                                      <span *ngIf="!!detail.approverLevel">Approver Level {{detail?.approverLevel}}</span>
                                    </div>
                                    <div class="col-6 align-right" >{{detail?.approveAt | date:'medium'}}</div>
                                  </div>
                                  <div class="row" style="font-size: 12px; padding-top: 5px !important;">
                                    <div class="col">{{detail?.approverComment}}</div>
                                  </div>
                                </div>
                              </div>
                            </mat-menu>
                      </div>
                      <div class="col-4" style="margin-top: 10px; text-align: right;">
                          <span style="color: #45C94E;">
                              {{ translateService.getLabel("approved") }}
                          </span>
                      </div>
                  </ng-template>
                  
              </div>
              <div *ngIf="!!comp.approvalSystem" class="row font-size-small">
                  <div class="col align-right" style="padding-right: 0;">
                    <em class="fa fa-info-circle info-icon" customToolTip [contentTemplate]="showTooltip"></em>
                    <span style="padding-right: 5px; padding-left: 3px;">Condition used: <b>{{comp.selectedCondition.conditionName}}</b></span>
                    <ng-template #showTooltip>
                        <div style="display: flex; flex-direction: column">
                          <span *ngFor="let condition of approvalService.allConditions">
                            <span *ngIf="comp.selectedCondition.id == condition.id">
                                <div class="tooltip-approver" style="padding-bottom: 8px;">{{condition.conditionName}}</div>
                                <div class="condition-desc">{{condition.description}}</div>
                                <ng-template ngFor [ngForOf]="condition.approverDetails" let-details let-i="index">
                                  <div style="padding-bottom: 2px;">
                                    <span class="tooltip-approver">Approver {{i+1}}: </span>
                                    <ng-template ngFor [ngForOf]="details.approverDetailsPerLevel" let-approver let-l="index">
                                      <span *ngIf="(details.approverDetailsPerLevel.length - 1) != l" [ngClass]="{'tooltip-userName' : approver.type == 'user', 'tooltip-groupName' : approver.type == 'group'}"> {{approver.name}},</span>
                                      <span *ngIf="(details.approverDetailsPerLevel.length - 1) == l" [ngClass]="{'tooltip-userName' : approver.type == 'user', 'tooltip-groupName' : approver.type == 'group'}"> {{approver.name}}</span>
                                    </ng-template>
                                  </div>
                                </ng-template>
                            </span>
                          </span>
                        </div>
                    </ng-template>
                  </div>
              </div>
          </mat-expansion-panel>
          <ng-template #captureComment let-applicationModal>
              <div class="modal-header">
                <div class="modal-title">
                  <span *ngIf="comp.actionName == 'Initiate'" style="font-size: 22px;">{{translateService.getLabel("initiate_approval")}}</span>
                  <span *ngIf="comp.actionName == 'Approved'" style="font-size: 22px;">{{translateService.getLabel("approve")}}</span>
                  <span *ngIf="comp.actionName == 'Rejected'" style="font-size: 22px;">{{translateService.getLabel("reject")}}</span>
                </div>
                <button #closeBtn type="button" class="close" aria-label="Close" (click)="checkListService.blurBG = false; applicationModal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
            
                <div class="modal-content">
                  <mat-form-field>
                    <textarea matInput placeholder="Enter Comment"  [(ngModel)]="comp.comment"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="modal-footer">
                <button *ngIf="comp.actionName == 'Initiate'" type="button" class="btn btn-save" (click)="updateApprovalSystemOnAction(comp); applicationModal.dismiss('Cross click')">
                  {{translateService.getLabel("initiate")}}
                </button>
                <button *ngIf="comp.actionName == 'Rejected'" type="button" class="btn btn-save" (click)="updateApprovalSystemOnAction(comp); applicationModal.dismiss('Cross click')">
                  {{translateService.getLabel("reject")}}
                </button>
                <button *ngIf="comp.actionName == 'Approved'" type="button" class="btn btn-save" (click)="updateApprovalSystemOnAction(comp); applicationModal.dismiss('Cross click')">
                  {{translateService.getLabel("approve")}}
                </button>
              </div>
            </ng-template>
      </ng-template>
  </mat-accordion>

  <div class="row checklist-panel-approval" *ngIf="showNoApprovalPendingMessage">
      <div class="col">
          <span style="font-size: 15px; color: #C2C3C7;"> 
          No pending Approvals for you at the moment
          </span>
      </div>
  </div>

</ng-template>

<ng-template [ngIf]="!checkListService.dataLoaded">
  <div class="loading-container">
    <span class="loading-text">
      <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="50"></mat-spinner>
    </span>
  </div>
</ng-template>

<ng-template #AP_Approve_Comment let-AP_Approve_CommentModal>
    <div class="modal-header">
      <div class="modal-title" style="width: 100%;">
        <h6>{{ translateService.getLabel("comment") }}</h6>
      </div>

      <button type="button" class="close" aria-label="{{ translateService.getLabel('close') }}" 
      (click)="AP_Approve_CommentModal.dismiss('Cross click'); approvalIniationInProgress = false;">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body" style="height: 30vh;">
        <div class="row" style="align-items: center; margin: 1vw">

            <div class="col"> {{ translateService.getLabel("comment") }} </div>

            <div class="col">
                
                <mat-form-field class="editable-form-field text-field">
                    <input matInput name="apComment" type="text" maxlength="100" 
                        [(ngModel)]="approvalComment" placeholder="{{ translateService.getLabel('comments') }}">
                </mat-form-field>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col">
                <button mat-raised-button class="secondary-bg" (click)="rejectRequest(approveRejectObj)">
                  {{ translateService.getLabel("reject") }}
                </button>
            </div>

            <div class="col">
                <button mat-raised-button class="primary-bg" (click)="approveRequest(approveRejectObj) ">
                  {{ translateService.getLabel("approve") }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<a style="display: none;" #downloadLink></a>