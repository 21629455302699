<div class="row" style="margin-top: 10px;">
  <div class="col-1" style="padding: 0; padding-left: 15px;">
  </div>
  <div class="col-7" style="margin-top: 7px; padding-right: 0;">
      <mat-form-field class="select-condition approval-items">
              <mat-select disableOptionCentering panelClass="myPanelClass" [placeholder]="translateService.getLabel('Type of report')"
                [(ngModel)]="seletedReportType" (selectionChange)="onSelectionOfReportType()">
                  <mat-option style="padding-right: 0;" *ngFor="let type of downloadReportService.reportTypes" [value]="type.reportType" 
                  [matTooltipPosition]="'left'" [matTooltip]="translateService.getLabel(type.tooltip)">
                    {{ type.reportName }}
                  </mat-option>
              </mat-select>
      </mat-form-field>                    
  </div>
  <div class="col-4" style="padding: 0; margin-top: 7px; text-align: right;">
      <button mat-button class="primary-bg approval-items initiate-btn" [class.btn-disabled]="downloadReportService.isDownloadInProgress || downloadReportService.isFetchInProgress || !seletedReportType" 
      [disabled]="downloadReportService.isDownloadInProgress || downloadReportService.isFetchInProgress || !seletedReportType" (click)="createAndGenerateNewReport()">
        <span *ngIf="!downloadReportService.isDownloadInProgress">
          {{ translateService.getLabel("Download Report") }}
        </span>
        <span *ngIf="downloadReportService.isDownloadInProgress">
          {{ translateService.getLabel("Downloading...") }}
        </span>
      </button>
  </div>
</div>

<div style="visibility: hidden;">
  <iframe *ngIf="downloadReportService.loadIframe" id="iframe" width="20%" height="1vh" frameBorder="0" [src]="downloadReportService.safeDashboardURL" style="height: 1vh;"></iframe>
</div>