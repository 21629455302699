import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { environment } from 'src/environments/environment';
import { InvestmentSummaryService } from '../investment-page-summary-v2/investment-summary.service';
import { FundListService } from '../fund-list-ui/fund-list.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UtilService } from 'src/app/utils/util.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadReportService {

  reportTypes = [];
  allReports = [];

  orgId;
  userId;
  orgName;
  fundId;
  fundName;

  isDownloadInProgress: boolean = false;
  isFetchInProgress: boolean = true;
  safeDashboardURL: SafeResourceUrl;
  loadIframe = false;

  constructor(private ds: DataService,
    private sanitizer: DomSanitizer,
    private utilService: UtilService,
    private ums: UserManagementService,
    private invSummaryService: InvestmentSummaryService,
    private fundService: FundListService,) {
  }

  init(fundId){
    const userDetails = this.ums.getSelectedUserDetails();
    this.orgId = userDetails?.organization.id
    this.userId = userDetails?.userId; 
    this.orgName = userDetails?.organization?.orgName;
    this.fundId = fundId;
    const getFundName = setInterval(()=> {
      if(this.fundService.allFunds && this.fundService.allFunds.length > 0){
        clearInterval(getFundName);
        this.fundName= this.fundService.getFundName(this.fundId);
      }
    }, 500)
    this.getAllReportTypes();
  }

  getAllReportTypes(){
    this.ds.getReportTypesByOrgIdReportLevel(this.orgId, 'COMPANY').subscribe(res => {
      this.reportTypes = res.body["response"];
    }, err => {
      console.log("Error while fetching report types, error: ", err);
    })
  }

  getAllReports(comp, seletedReportType){
    this.ds.getReportsBySource(comp.companyId, comp.latestValuationDate, 'ONE_CLICK', seletedReportType, this.userId).subscribe(res => {
      this.allReports = res.body["response"];
      this.isFetchInProgress = false;
    }, err => {
      console.log("Error occurred while fetching all reports by source, error: ", err)
    })
  }

  createNewReport(comp, seletedReportType){

    const selectedReport = this.reportTypes.find(r => r.reportType == seletedReportType);

    const reportName = this.fundName + '_' + comp.companyName + '_' + comp.latestValuationDate + '_' + selectedReport.reportName;

    const reportInput = {
      "reportInput": [
        {
          "name": this.fundName,
          "type": "FUND",
          "companies": [
            {
              "id": comp.companyId,
              "name": comp.companyName,
              "sector": this.invSummaryService.investmentDateValuation.sector || null ,
              "logo": comp.logo || null,
              "exitStatus": this.invSummaryService.investmentDateValuation.exitStatus,
              "investmentDate": comp.investmentDate
            }
          ],
          "fundId": this.fundId,
          "yellowReportName": this.fundName,
          "orgId": this.orgId,
          "userId": this.userId
        }
      ],
      "orgId": this.orgId,
      "orgName": this.orgName,
      "userId": this.userId,
      "fundsType": "FUND"
    }


    const payload = {
      createdBy: this.userId,
      reportName: reportName,
      reportType: seletedReportType,
      reportLevel: 'COMPANY',
      reportLevelIds: comp.companyId,
      source: 'ONE_CLICK',
      reportAsOfDate: comp.latestValuationDate,
      reportInput: JSON.stringify(reportInput)
    }
    
    this.ds.createNewReport(payload).subscribe( res => {
      const apiResponse = res.body;
      if(apiResponse){
        const createdReportId = apiResponse['response'];
        const isReportCreationSuccessfull = apiResponse['success'];
        if(!this.allReports || this.allReports.length === 0)
          this.allReports.push({  //updating 'allReports' so that it won't create multiple reports for the same company 
            'id': createdReportId,
            'reportType': seletedReportType,
            'reportName': reportName
          });
        if(isReportCreationSuccessfull)
        this.generateReportData(createdReportId, seletedReportType, reportName);
      }
    }, err => {
      console.log("Error occurred while creating a new report , error: ", err);
      this.utilService.showMessage("Failed to create a report.","Ok");
    })
  }

  generateReportData(reportId, reportType, reportName){
    this.ds.generateReportData(reportId).subscribe( res => {
      const url = environment.portalUrl + '/dashboard/#/equity-report-download?id=' + reportId + '&type=' + reportType + '&name=' + reportName;
      this.safeDashboardURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.loadIframe = true;
    }, err => {
      this.isDownloadInProgress = false;
      console.log("Error occurred while generating the report, error: ", err)
      this.utilService.showMessage("Failed to generate a report.","Ok");
    })
  }
}