import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from 'src/app/services/translation.service';
import { DownloadReportService } from './download-report.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-download-report-ui',
  templateUrl: './download-report-ui.component.html',
  styleUrls: ['./download-report-ui.component.scss']
})
export class DownloadReportUIComponent implements OnInit {

  @Input() subjectCompany;

  constructor(
    public downloadReportService: DownloadReportService,
    public translateService: TranslateService) { }

  seletedReportType;

  ngOnInit() {
    this.downloadReportService.loadIframe = false;
    window.addEventListener('message', ({ data }) => {
      console.log(data);
      this.downloadReportService.isDownloadInProgress = false;
    });
  }

  onSelectionOfReportType(){
    this.downloadReportService.isFetchInProgress = true;
    this.downloadReportService.getAllReports(this.subjectCompany, this.seletedReportType);
  }

  createAndGenerateNewReport(){
    this.downloadReportService.isDownloadInProgress = true;
    if(!this.downloadReportService.allReports || this.downloadReportService.allReports.length === 0){
      this.downloadReportService.createNewReport(this.subjectCompany, this.seletedReportType);
    }else{
      const reportDetails = cloneDeep(this.downloadReportService.allReports[0]);
      this.downloadReportService.generateReportData(reportDetails.id, reportDetails.reportType, reportDetails.reportName);
    }
  }
}