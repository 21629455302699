import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { clone, cloneDeep } from 'lodash';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { PortFolioService } from '../portfolio.service';
import { UtilService } from 'src/app/utils/util.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FundListService } from '../fund-list-ui/fund-list.service';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { InvestmentSummaryService } from '../investment-page-summary-v2/investment-summary.service';
import moment from 'moment';
import { MessageService } from 'src/app/services/message.service';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';
import { NotificationService } from 'src/app/utils/notification/notification.service';
import { PortFolioSummaryServiceV2 } from '../portfolio-summary-v2/portfolio-summary-v2.service';
import { TranslateService } from 'src/app/services/translation.service';
import { ApprovalMatrixService } from 'src/app/qubit-x/portfolio/check-list-pop-up/approval-matrix/approval-matrix.service';
import { CheckListService } from '../check-list-pop-up/check-list.service';
import { ApprovalSystemService } from 'src/app/services/approval-system.service';
import { CommentServiceService } from 'src/app/utils/comment-service.service';
import { DownloadReportService } from '../download-report-ui/download-report.service';

@Component({
  selector: 'app-company-valuation-v2',
  templateUrl: './company-valuation-v2.component.html',
  styleUrls: ['./company-valuation-v2.component.scss']
})
export class CompanyValuationV2Component implements OnInit, OnDestroy {
  @ViewChild('valuationDatesGrid', { static: false }) public valuationDatesGrid: GridComponent;

  fundId;
  loggedInUser;
  investmentDateInfo;

  loadInvSummary = false;

  primaryCompanyId;
  selectedCompanyDates = [];
  
  selectedCompanyConsolForm;
  
  valDateUpperLimit;

  allApprovalDataArray;
  approvalConditionData;
  allApprovalDetails;

  selectedFormVersions;

  initiatorsGroupMembers;

  typeOfModes = {
    VIEW_TYPE: "VIEW_TYPE",
    EDIT_TYPE: "EDIT_TYPE",
    SHARE_TYPE: "SHARE_TYPE"
  }

  shareInputName;
  shareInputEmail;

  showBuildUpSummary = false;

  showUserValDates = false;
  showValDates = false;
  showValDatesBeta = false;
  showConsolSummary = false;
  showInvestment = false;

  valuationDateGridDataSource = [];

  valuationDatesWithSummaryData = {}

  approveRejectObj;
  trackRecordData;
  showApproveDialogBox;


  // buEditMode = false;
  // buShareMode = false;
  selectedBusinessUnits;

  selectedFormToShare;

  newCompany = {name: "", valuationDate: null, groupFormId: null, security: "EQUITY", businessUnitsNumber: 0, versionName: "" , replicatedValDate: null, replicatedValDateId: null, updateMarketData:true, marketDataDate: "", refreshInvesteefinancials:false, financialRefreshDate: ""};

  updateValueBridge = false;

  showVersionNameDialog = false;
  selectedVersionForCopy = null;

  selectedCompanyDatesWithAllVersions = []

  allSummaryDataWithAllVersions = {}

  showUpdateVersionNameDialog = false;
  selectedVersionForUpdate = null;

  newFormVersion = "";

  newVersionName = "";

  companyValuationSettings = {
    "metric": "bevRevenue",
    "secondaryMetric":"bevEbitda",
    "period": "fy",
    "secondaryPeriod":"fy",
    "currency": "EUR",
    "valueBridge": "NAV"
  }

  companyValuationSettingsInput = {
    "metric": "bevRevenue",
    "secondaryMetric":"bevEbitda",
    "period": "fy",
    "secondaryPeriod":"fy",
    "currency": "EUR",
    "valueBridge": "NAV"
  }

  // filterGuestUserData=[];
  // guestUserData = [
  //   {
  //     "userId": "abcd@gmail.com",
  //     "userName": "ABCD Well",
  //     "id": "dsfsdf-sdfdsfsd-fsdfdsfs"
  //   },
  //   {
  //     "userId": "xyz@gmail.com",
  //     "userName": "XYZ Well",
  //     "id": "xyz-sdfdsfsd-fsdfdsfs"
  //   },
  //   {
  //     "userId": "demo@gmail.com",
  //     "userName": "demo Well",
  //     "id": "dsfsdf-demo-fsdfdsfs"
  //   },
  //   {
  //     "userId": "infosys@gmail.com",
  //     "userName": "infosys",
  //     "id": "xyz-infosys-fsdfdsfs"
  //   },
  //   {
  //     "userId": "tcs@gmail.com",
  //     "userName": "tcs",
  //     "id": "dsfsdf-tcs-fsdfdsfs"
  //   },
  //   {
  //     "userId": "HCL@gmail.com",
  //     "userName": "HCL",
  //     "id": "xyz-HCL-fsdfdsfs"
  //   },
  //   {
  //     "userId": "accenture@gmail.com",
  //     "userName": "Accenture",
  //     "id": "dsfsdf-accenture-fsdfdsfs"
  //   },
  //   {
  //     "userId": "mahindra@gmail.com",
  //     "userName": "Tech Mahindra",
  //     "id": "xyz-mahindra-fsdfdsfs"
  //   },
  //   {
  //     "userId": "Microsoft@gmail.com",
  //     "userName": "Microsoft",
  //     "id": "dsfsdf-Microsoft-fsdfdsfs"
  //   },
  //   {
  //     "userId": "Apple@gmail.com",
  //     "userName": "Apple",
  //     "id": "xyz-Apple-fsdfdsfs"
  //   },
  //   {
  //     "userId": "Cisco@gmail.com",
  //     "userName": "Cisco",
  //     "id": "dsfsdf-Cisco-fsdfdsfs"
  //   },
  //   {
  //     "userId": "IBM@gmail.com",
  //     "userName": "IBM",
  //     "id": "xyz-IBM-fsdfdsfs"
  //   }
  // ]


  // unSubmittedForms = [];

  showPage = "Investment Summary"
  showPageId = ""

  env = environment.environment;
  
  activeCommentIconId = 0;
  
  mode = this.typeOfModes.VIEW_TYPE;

  approvalRouteUpdatedSubscription;

  latestValuationDate

  constructor(private activatedRoute: ActivatedRoute, public ums: UserManagementService,
    private ds: DataService, public portfolioService : PortFolioService,
    public ps: PortFolioSummaryServiceV2,
    private utilService: UtilService, private route : ActivatedRoute,
    private modalService : NgbModal,
    private fundService : FundListService,
    private titlecasePipe: TitleCasePipe,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    public invSummaryService : InvestmentSummaryService,
    private ms : MessageService, public currencyExchange: CurrencyExchangeService,
    public translateService: TranslateService,
    public approvalMatrixService: ApprovalMatrixService,
    private downloadReportService: DownloadReportService,
    private approvalService: ApprovalSystemService,
    public commentService : CommentServiceService,
    private checkListServive: CheckListService) { 

      this.approvalRouteUpdatedSubscription = approvalMatrixService.$approvalRouteUpdated.subscribe(update => {
        this.prepareValuationGridDataSource(false);
      })
  }

  ngOnDestroy(): void {
    if(this.approvalRouteUpdatedSubscription) {
      this.approvalRouteUpdatedSubscription.unsubscribe();
    }
  }

  async ngOnInit(upperLimit?) {
    this.loggedInUser = this.ums.getSelectedUserDetails();
    this.fundId = this.route.snapshot.queryParamMap.get("parentId");

    this.invSummaryService.allValuationDates = []

    const showPage = this.route.snapshot.queryParamMap.get("showPage");
    const showPageId = this.route.snapshot.queryParamMap.get("showPageId");

    if(showPage && showPageId){
      this.showPage = showPage;
      this.showPageId = showPageId;

      if(this.showPage == "consol"){
        this.showConsolSummary = true;
      }
    }

    this.ps.selectedFundId = this.fundId;

    await this.ps.initPortfolioSummaryWidgetdata()

    this.investmentDateInfo = null;
    this.invSummaryService.showSettings = false;

    this.notificationService.notifyStatusUpdate.subscribe(x => {
      if(x === true){
        //refresh page
      this.prepareDataSourceForAllValDatesWithVersions();
      }    
    });
    this.activatedRoute.paramMap.subscribe( async params => {
      this.investmentDateInfo = {};
    
      this.primaryCompanyId = params.get("company");
      this.invSummaryService.companyId = this.primaryCompanyId;
    
      this.ps.getUserEntityAccess(this.fundId, this.primaryCompanyId); //fetching user access;

      this.reloadValdates(upperLimit);
      
    }, error => {
      console.log("Failed to fetch All version Forms", error)
    });

    this.notificationService.notificationStatusUpdateWithAllDetails.subscribe((details : any) => {      
      this.refreshIfTheFormIsSubmitted(details)
    })

    this.ms.subscribe("refreshComments", data =>{
      if(data.refresh == true){
      this.fetchCommentsAndRefreshTable(data.id);
      }
    })

    this.downloadReportService.init(this.fundId);
  }

  reloadValdates(upperLimit?){
    this.ds.getAllValuationDateVersionsByCompanyIdAndUserIdAndOrgId(this.primaryCompanyId, this.loggedInUser.id, this.loggedInUser.organization.id).subscribe(async res => {

      const valDates = res.body["response"];

      this.selectedCompanyDatesWithAllVersions = valDates;


      this.selectedCompanyDatesWithAllVersions = this.selectedCompanyDatesWithAllVersions.sort((f1, f2) => {
        const f1Date = new Date(f1.valuationDate);
        const f2Date = new Date(f2.valuationDate);
        return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
      })

      this.invSummaryService.allValuationDatesWithVersions = valDates;
      this.commentService.valuationDatetoFetchComments = valDates

      // this.unSubmittedForms = this.invSummaryService.allValuationDatesWithVersions.filter(form => {
      //   const details = JSON.parse(form.details);
      //   return details.status == "Initiated";
      // });

      this.selectedCompanyDatesWithAllVersions = this.selectedCompanyDatesWithAllVersions.map(comp => {
        if(comp.businessUnits && comp.businessUnits.length > 0){
          comp.businessUnits = this.invSummaryService.addInfoToFormobjectFromDetails(comp.businessUnits)
        }
        return comp;
      })

      this.invSummaryService.addInfoToFormobjectFromDetails(this.selectedCompanyDatesWithAllVersions);

      this.invSummaryService.allValuationDates = this.invSummaryService
                  .addInfoToFormobjectFromDetails(this.selectedCompanyDatesWithAllVersions.filter(comp => comp.frozen))
                  .sort( function(a,b) { return (b.valuationDate) - (a.valuationDate) });

      // try {
      //   const valDateUpperLimitSavedAPI = await this.ds.getWidgetDataFromDB("VAL_DATES_UPPERLIMIT", this.primaryCompanyId).toPromise();
      //   this.valDateUpperLimit = valDateUpperLimitSavedAPI.body["response"][0]["widgetData"].limit;
      // } catch(e) {
      //   this.valDateUpperLimit = null;
      // }

      if(upperLimit){
        this.valDateUpperLimit = upperLimit
      }

      this.latestValuationDate = this.invSummaryService.allValuationDates.find(form => {
        const details = JSON.parse(form.details);
        return details && details.status == "Submitted";
      })

      if(!this.valDateUpperLimit) {
        const latestValDate = this.invSummaryService.allValuationDates.find(form => {
          const details = JSON.parse(form.details);
          return details && details.status == "Submitted";
        })
        this.valDateUpperLimit = latestValDate 
        ? latestValDate.valuationDate 
        : this.invSummaryService.allValuationDates[0].valuationDate;
      }

      //Get all valuation dates which are on or before upper limit
      const upperLimitIndex = this.invSummaryService.allValuationDates.findIndex(vd => vd.valuationDate == this.valDateUpperLimit);
      this.selectedCompanyDates = this.invSummaryService.allValuationDates.slice(upperLimitIndex, this.invSummaryService.allValuationDates.length);
      
      let latestSubmittedForm = this.selectedCompanyDates.find( comp =>comp.status !== "Initiated" && comp.frozen);
      let latestDateId = latestSubmittedForm ? latestSubmittedForm.id : "";
      
      const invDateForm = this.selectedCompanyDates.find(comp => comp.groupFormId == null);

      // investment date should always be part of Investment Summary.
      if(!invDateForm) {
        const invDateForm = this.invSummaryService.allValuationDates.find(comp => comp.groupFormId == null);
        this.selectedCompanyDates.push(invDateForm);
        this.selectedCompanyDates = this.selectedCompanyDates.sort( function(a,b) { return (b.valuationDate) - (a.valuationDate) });
      }

      this.newCompany.name = invDateForm.companyName;
      this.newCompany.groupFormId = this.primaryCompanyId;
      this.newCompany.businessUnitsNumber = this.invSummaryService.allValuationDates[0].businessUnitsNumber ; 

      this.ds.getFormData(latestDateId, 4).subscribe(async ver => {
        const formData = ver.body["response"].formData;

        try {
          const settingsData = await this.ds.getWidgetDataFromDB("INVESTMENT_SUMMARY_SETTINGS", this.primaryCompanyId).toPromise();
          const companyValuationSettings = settingsData.body["response"][0]["widgetData"];

          if(!companyValuationSettings.secondaryMetric) {
            companyValuationSettings.secondaryMetric = this.companyValuationSettings.secondaryMetric;
            companyValuationSettings.secondaryPeriod = this.companyValuationSettings.secondaryPeriod;
          }

          this.companyValuationSettings = companyValuationSettings;
          
        } catch(ex) {
          this.companyValuationSettings.currency = formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY;
        }

        this.companyValuationSettings.currency = this.ps.selectedCurrency;

        this.companyValuationSettingsInput = cloneDeep(this.companyValuationSettings);

        this.investmentDateInfo.formData = formData;
      }, error => {
        console.log("Failed to fetch formData", error)
      });

      this.selectedCompanyConsolForm = cloneDeep(invDateForm); //loading consol summary of latest valuation date by default

      if(this.selectedCompanyDates.length > 0){
        this.prepareDataSourceForAllValDatesWithVersions();
      }

      this.utilService.closeAllPopups();
    });
  }

  updateApprovalMatrix(request){
    switch (request.action) {
      case 'initiate':
        this.initiateApprovalRequest(request.data);
        break;
      case 'recall':
        this.recallApprovalRequest(request.data);
        break;
      case 'approveOrReject':
        this.enableApproveDialogBox(request.data);
      default:
        break;
    }
  }
  
  infoChange(item){
    const details = JSON.parse(item.details)
    item.createdByName = details.createdByName ?? details.vcName;
    
  }


  refreshIfTheFormIsSubmitted(details){
    const info = JSON.parse(details.label);
    const companyId = info.companyId;

    if(companyId == this.primaryCompanyId){
      window.location.reload();
    }
    // try {
    //   const formIsSubmitted = this.unSubmittedForms.find(form => {
    //     return form.id == details.eventObjectId 
    //       && form.fundCompany == details.eventObjectParentId 
    //   })
  
    //   if(formIsSubmitted){
    //     window.location.reload();
    //   }
    // } catch (error) {
    //   console.log("Failed to load Company Valuation Dates", error);
    // }
  }

  saveInvestmentSummarySettings(){
    
    this.ds.saveWidgetDataToDB("INVESTMENT_SUMMARY_SETTINGS", this.companyValuationSettingsInput, this.primaryCompanyId).subscribe(res => {
      console.log("response", res);
      this.companyValuationSettings = cloneDeep(this.companyValuationSettingsInput);
      this.invSummaryService.showSettings = false;
      this.utilService.showMessage(this.translateService.getLabel("suc_setting_save"), this.translateService.getLabel("ok"));
    }, error => {
      this.utilService.showMessage(this.translateService.getLabel("err_saving_setting"), this.translateService.getLabel("ok"), true);
    })

    //Updating Attributes
    this.invSummaryService.updateSummaryAndValuationDateAttributesByCompany(this.primaryCompanyId, 
      this.fundId, this.invSummaryService.INVESTMENT_SUMMARY_SETTINGS_SAVE);
  }


 async initiateApprovalRequest(valDateObject) 
 {
   this.utilService.showLoadingPopup();
   const objectNameJson={
    "companyName":valDateObject.companyName,
    "valDate":valDateObject.valuationDate,
    "version":valDateObject.versionName
  }
   let requestObj = {
     new: {
       "id": "",
       "conditionId": this.approvalConditionData.conditionId,
       "conditionName": this.approvalConditionData.conditionName,
       "conditionLevel": this.approvalConditionData.conditionLevel,
       "fundId": this.fundId,
       "firmId": this.loggedInUser.organization.id,
       "companyId": this.primaryCompanyId,
       "objectLocked": "1",
       "status": "pending",
       "objectName":JSON.stringify(objectNameJson),
       "objectId": valDateObject.id,
       "objectContent": environment.portalUrl + "/pv/#/valuation-summary?id=" + valDateObject.id + "&fv=4",
       "objectType": "valuation",
       "sendEmail": false
     }
   }

   requestObj.new["approvals"] = [];

   this.approvalConditionData.approvers.forEach((approver, index) => {
     if(approver.type == "group")
     {
       let groupObj = {
         "group": {
           "id": approver.id,
           "name": approver.name
         },
         "status": "pending",
         "comments": "",
         "timestamp": ""
       }

       requestObj.new["approvals"].push(groupObj);
     }
     else
     {
       let userObj = {
         "user": {
           "id": approver.id,
           "name": approver.name
         },
         "status": "pending",
         "comments": "",
         "timestamp": ""
       }

       requestObj.new["approvals"].push(userObj);
     }
   });  

   if(requestObj.new.conditionId)
   {
     let requestCreationStatus = await this.ds.initiateApprovalRequest(requestObj);

     if(requestCreationStatus.status == 200)
     {
       valDateObject.approvalRequestId = requestCreationStatus.body["response"].id;
       this.approveRequest(valDateObject);
     }
   }

 }

 disableRollOver(){
    //If there are no valuation dates - disabling the rollover button
    if(this.invSummaryService.allValuationDates.length == 0){
      return true;
    }
    //If there is any unsubmitted form - disabling the rollover button
    else{
      const invFormIsNotSubmitted = this.invSummaryService.allValuationDates
      .find(comp => comp.status.toUpperCase() == "INITIATED" && !comp.groupFormId);
      return invFormIsNotSubmitted ? true : false;
    }
 }


 recallApprovalRequest(valDateObject)
 {
   this.utilService.showLoadingPopup();
   let recallObj = {
     "approvalRequestID": valDateObject.approvalRequestId,
     "userId": this.getLoggedInUserId(),
     "comments": "",
     "status": "recalled",
     "sendEmail": true
   }

   if(valDateObject.comments == null)
   {
     recallObj.comments = "Recalled"
   }
   else
   {
     recallObj.comments = valDateObject.comments;
   }

   this.ds.recallRequest(recallObj).then(() => {
    recallObj["valDateId"] = valDateObject.id;
    this.prepareValuationGridDataSource(true, recallObj);
   });
   
 }

 approveRequest(valDateObject)
 {
   this.utilService.showLoadingPopup();
   this.hideApproveDialog();
   let approveObj = {
     "approvalRequestID": valDateObject.approvalRequestId,
     "userId": this.getLoggedInUserId(),
     "comments": "",
     "status": "approved",
     "sendEmail": true
   }

   if(valDateObject.comments == null)
   {
     approveObj.comments = "Approved"
   }
   else
   {
     approveObj.comments = valDateObject.comments;
   }

   this.ds.approveRequest(approveObj).then(() => {
    approveObj["valDateId"] = valDateObject.id;
    this.prepareValuationGridDataSource(true, approveObj);
   });
   
 }

 rejectRequest(valDateObject)
 {
   this.utilService.showLoadingPopup();
   this.hideApproveDialog();
   let rejectObj = {
     "approvalRequestID": valDateObject.approvalRequestId,
     "userId": this.getLoggedInUserId(),
     "comments": "",
     "status": "rejected",
     "sendEmail": true
   }

   if(valDateObject.comments == null)
   {
     rejectObj.comments = "Rejected"
   }
   else
   {
     rejectObj.comments = valDateObject.comments;
   }
   
   this.ds.rejectRequest(rejectObj).then(() => {
    rejectObj["valDateId"] = valDateObject.id;
    this.prepareValuationGridDataSource(true, rejectObj);
    this.utilService.closeAllPopups();
   },
   err =>{
     this.utilService.showMessage("Failed to reject the approval request", "Ok", true)
   });
 }

  openSettingsOption(){
    this.invSummaryService.showSettings = true;

    this.invSummaryService.getToSettingsOptions();
  }

  closeSettingsOption(){
    this.invSummaryService.showSettings = false;
  }

  hideApproveDialog() {
    this.showApproveDialogBox = false;
    this.approveRejectObj = null;
    this.utilService.closeAllPopups();
  }

  enableApproveDialogBox(valDate){
    this.utilService.showLoadingPopup();
    this.approveRejectObj = valDate;
    this.trackRecordData = undefined;

    this.showApproveDialogBox = true;
  }

  initValueBridge(){
    this.updateValueBridge = undefined

    setTimeout(() => {
      this.updateValueBridge = true;
    }, 400);
  }

  prepareDataSourceForAllValDatesWithVersions(){
    this.allApprovalDataArray = [];
    let allRequestIds = [];
    this.approvalConditionData = [];
    this.valuationDateGridDataSource = [
      {
        "loading": true
      }
    ];

    if(this.valuationDatesGrid) {
      this.valuationDatesGrid.refresh();
    }

    let allValDates = [];
    console.log("dates", this.selectedCompanyDatesWithAllVersions);
    this.selectedCompanyDatesWithAllVersions.forEach(eachDate => {
      allValDates.push(eachDate.valuationDate);
    });
    
    const allFormIds = this.selectedCompanyDatesWithAllVersions.map(comp => comp.id);

    this.ds.getSummaryOfFormsByFormIdsV2(allFormIds, this.fundId).subscribe(async(res) => {

      this.allSummaryDataWithAllVersions = res.body["response"];

      this.selectedCompanyDatesWithAllVersions = this.selectedCompanyDatesWithAllVersions.map(comp => {
        if(this.allSummaryDataWithAllVersions && this.allSummaryDataWithAllVersions[comp.id]){
          comp["investment"] = this.allSummaryDataWithAllVersions[comp.id]["investment"];

          // ToDo : Update WRT BU
          if(this.allSummaryDataWithAllVersions[comp.id]["consolSummary"]){
            comp["consolSummary"] = this.allSummaryDataWithAllVersions[comp.id]["consolSummary"];
            comp["businessUnits"] = this.invSummaryService.addInfoToFormobjectFromDetails(this.allSummaryDataWithAllVersions[comp.id]["businessUnits"]);

            comp["businessUnits"] = comp["businessUnits"].sort((bu1, bu2) => {
              if(bu1.businessUnitName < bu2.businessUnitName) { return -1; }
              if(bu1.businessUnitName > bu2.businessUnitName) { return 1; }
              return 0;
            });
          }
        }
        return comp;
      })

      this.invSummaryService.valuationDateSummaryReady$.next({});

      if(this.showPage == "consol"){
        const selectedVersion = this.selectedCompanyDatesWithAllVersions.find(v => v.id == this.showPageId);
        await this.showByTopMenu('consol', selectedVersion);
      }
      
      const allValuationDates : Set<string> = new Set(this.selectedCompanyDatesWithAllVersions.map(comp => comp.valuationDate));

      this.valuationDateGridDataSource = []

      let index = 0;
      allValuationDates.forEach((valDate) => {
        this.valuationDateGridDataSource.push({
          name: valDate,
          index: index,
          value: this.selectedCompanyDatesWithAllVersions.filter(comp => {
            return this.utilService.compareDates(new Date(comp.valuationDate), new Date(valDate)) == 0;
          })
        })

        index++
      })

      //sorting versions inside a valuation date
      this.valuationDateGridDataSource.forEach( valDate => {
        valDate.value.sort((a,b) =>{
            return new Date(a.createdDate).valueOf() - new Date(b.createdDate).valueOf()
        })
      })

      this.valuationDateGridDataSource.forEach(formVersionForGrid => {
        formVersionForGrid.value.forEach(eachVersion => {
            eachVersion.selectedCondition = {
              "conditionName": "",
              "id": ""
            }
            eachVersion.approvalSystemStatus = eachVersion.approvalSystem || eachVersion.approvalStatus;
        })
      })

      try {
        this.selectedCompanyDatesWithAllVersions.forEach(form => {
          if(form.businessUnits && form.consolSummary){
            this.invSummaryService.businessUnitsValSummaryForAllIds.summary[form.id] = form.consolSummary
            if(this.allSummaryDataWithAllVersions && this.allSummaryDataWithAllVersions[form.id]["currencyExchangeRates"]){
              const currKeys = Object.keys(this.allSummaryDataWithAllVersions[form.id]["currencyExchangeRates"]);
              currKeys.forEach(key => {
                this.invSummaryService.businessUnitsValSummaryForAllIds.currency[key] = this.allSummaryDataWithAllVersions[form.id]["currencyExchangeRates"][key];
              })
            }
          }
        })
  
        this.selectedCompanyDatesWithAllVersions.forEach(form => {
          if(form.businessUnits && form.businessUnits.length > 0){
            this.invSummaryService.initBU_ConsolSummary(form)
          }
        })
      } 
      catch (error) {
        
      }

      console.log("valuationDateGridDataSource", this.valuationDateGridDataSource);

      try {
        this.approvalConditionData = await this.ds.getApprovalCondition(this.loggedInUser.organization.id, this.primaryCompanyId, this.fundId);
        this.approvalConditionData = this.approvalConditionData.body.response;
  
        if(this.approvalConditionData && this.approvalConditionData.approvers[0].type == "group") {
          this.initiatorsGroupMembers = (await this.ds.getGroupById(this.approvalConditionData.approvers[0].id)).body["response"].members;
        }
      } catch(e) {
        console.log("Error is getting condition and members");
      }
  
      this.selectedCompanyDatesWithAllVersions.forEach((eachDate) => {
        if(eachDate.approvalRequestId){
          allRequestIds.push(eachDate.approvalRequestId)
        }
      });
  
      this.allApprovalDetails = await this.approvalMatrixService.getApprovalDataForAllIds(allRequestIds, this.loggedInUser.organization.id);
  
      this.valuationDateGridDataSource = [];
      
      this.selectedCompanyDatesWithAllVersions.forEach((eachDate) => {
        this.getActionNameAndStatus(eachDate);
      });
  
      this.prepareGroupledValVersions();
  
      if(this.valuationDatesGrid) {
        this.valuationDatesGrid.refresh();
      }
      // this.utilService.closeAllPopups()      
    }, error => {
      console.log("Failed to fetch Summary for the FormIds", error)
    });
  }

  updateInvestmentInForm(){
    this.showPage = "Investment Summary"
    this.prepareDataSourceForAllValDatesWithVersions();
  }

  getBusinessUnitParameterValue(parameter, businessUnits) {
    let total = 0
    
    businessUnits.forEach( (bu) => {
      if(bu.investment) {
        total += bu.investment.equityValue[parameter]
      }
    })

    return total;
  }

  async prepareValuationGridDataSource(refreshChecklist, approvalData?){
    this.allApprovalDataArray = [];
    let allRequestIds = [];
    this.approvalConditionData = [];
    this.valuationDateGridDataSource = [
      {
        "loading": true
      }
    ];

    if(this.valuationDatesGrid) {
      this.valuationDatesGrid.refresh();
    }

    let allValDates = [];
    console.log("dates", this.selectedCompanyDates);
    this.selectedCompanyDates.forEach(eachDate => {
      allValDates.push(eachDate.valuationDate);
    });

    let response;
    try {
      response = await this.ds.getAllFormsVersionsForAllValDates(this.primaryCompanyId, allValDates);
    } catch(e) {
      response = null;
    }

    let allVerAllValDate = [];

    if(response.body["response"][0]) {
      let allVersions = response.body["response"][0];
      // for (const key in allVersions){
      //   allVersions[key].forEach(entry => {
      //     allVerAllValDate.push(entry)
      //   });
      // }
      allValDates.forEach(eachate => {
        if(allVersions[eachate]) {
          const sortedVersionData = allVersions[eachate].sort((a,b) =>{
            return new Date(a.createdDate).valueOf() - new Date(b.createdDate).valueOf()
          });
          allVersions[eachate] = sortedVersionData;

          allVersions[eachate].forEach(entry => {
            allVerAllValDate.push(entry)
          });
        }
      }); 

    } else {
      return;
    }

    try {
      const allConsolFormsIds = allVerAllValDate.map( comp => comp.id);
      const apiData = await this.ds.getBUSummaryDataAndExchangeRates(allConsolFormsIds).toPromise();
      const buSummaryAPIData = apiData.body['response'];

      if(buSummaryAPIData.currencyExchangeRates) {
        Object.keys(buSummaryAPIData.currencyExchangeRates).forEach( key => {
          this.invSummaryService.businessUnitsValSummaryForAllIds.currency[key] = buSummaryAPIData.currencyExchangeRates[key];
          // this.invSummaryService.businessUnitsValSummaryForAllIds.currency[key] = buSummaryAPIData.currencyExchangeRates[key];
        })
      }

      for(let consolForm of allVerAllValDate) {
        if(consolForm.hasOwnProperty("businessUnits")) {
          const consolFormId = consolForm.id;
    
          if(buSummaryAPIData && buSummaryAPIData.consolFormWiseData && buSummaryAPIData.consolFormWiseData[consolFormId]){
            if(buSummaryAPIData.consolFormWiseData[consolFormId].consolSummary){
              this.invSummaryService.businessUnitsValSummaryForAllIds.summary[consolFormId] = buSummaryAPIData.consolFormWiseData[consolFormId].consolSummary;
              // this.invSummaryService.businessUnitsValSummaryForAllIds.summary[consolFormId] = buSummaryAPIData.consolFormWiseData[consolFormId].consolSummary;
            }
    
            if(buSummaryAPIData.consolFormWiseData[consolFormId].investment){
              consolForm.investment = buSummaryAPIData.consolFormWiseData[consolFormId].investment
            }
          } else if(buSummaryAPIData && buSummaryAPIData.consolFormWiseData){
            buSummaryAPIData.consolFormWiseData[consolFormId] = {
              consolSummary: {
              },

              investment: {
                currency : "",
                enterpriseValue: 0,
          
                equityValue: {
                  weightedEquityValue: 0,
                  finalAdjustedEquityVal: 0,
                  stake: 100,
                  finalStakeValue: 0,
                  stakeValue: 0,
                }
              }
            }

            this.invSummaryService.businessUnitsValSummaryForAllIds.summary[consolFormId] = buSummaryAPIData.consolFormWiseData[consolFormId].consolSummary;
            // this.portfolioService.invSummaryService.businessUnitsValSummaryForAllIds.summary[consolFormId] = buSummaryAPIData.consolFormWiseData[consolFormId].consolSummary;
          }

          const form = consolForm; 
          const details = JSON.parse(form.details);
      
          if(details) {               
            if(!form.companyName) {
              form.companyName = details["companyName"];
              form.companyNameInForm = details["companyNameInForm"];
              
            } else {
              form.companyNameInForm = form.companyName;
            }
      
            form.versionName = details["versionName"];
            form.frozen = details["frozen"];
            form.status = details["status"];
            form.currency = details["currency"];
            form.businessUnitsNumber = details["businessUnitsNumber"];
      
            if(form.businessUnits) {
              this.invSummaryService.initBU_ConsolSummary(form);
            }
          }
        }
      }
    } catch (error) {
      console.log("Failed to fetch Consol Summary Data for all consol forms", error);
    }

    try {
      this.approvalConditionData = await this.ds.getApprovalCondition(this.loggedInUser.organization.id, this.primaryCompanyId, this.fundId);
      this.approvalConditionData = this.approvalConditionData.body.response;

      if(this.approvalConditionData && this.approvalConditionData.approvers[0].type == "group") {
        this.initiatorsGroupMembers = (await this.ds.getGroupById(this.approvalConditionData.approvers[0].id)).body["response"].members;
      }
    } catch(e) {
      console.log("Error is getting condition and members");
    }

    allVerAllValDate.forEach((eachDate) => {
      if(eachDate.approvalRequestId){
        allRequestIds.push(eachDate.approvalRequestId)
      }
    });

    this.allApprovalDetails = await this.approvalMatrixService.getApprovalDataForAllIds(allRequestIds, this.loggedInUser.organization.id)
    
    this.valuationDateGridDataSource = [];
    allVerAllValDate.forEach((eachDate) => {
      this.getActionNameAndStatus(eachDate);
    });

    this.prepareGroupledValVersions();

    if(this.valuationDatesGrid) {
      this.valuationDatesGrid.refresh();
    }
    this.utilService.closeAllPopups()

    console.log("Showing list of valuation dates in Investment summary", this.valuationDateGridDataSource)

    if(refreshChecklist && approvalData) {
      console.log("Approval Matrix: Sync checklist with Investment summary", approvalData)
      await this.checkListServive.setNewApprovalIdToCompany(approvalData.valDateId, approvalData.approvalRequestID);
    }
  }

  async getActionNameAndStatus(formVersion){
      let formVersionForGrid = cloneDeep(formVersion);

      if(formVersion?.investment?.equityValue?.finalStakeValue) {
        formVersionForGrid["stake"] = formVersion.investment.equityValue.finalStakeValue
      } else if(formVersion?.investment?.equityValue?.stakeValue) {
        formVersionForGrid["stake"] = formVersion.investment.equityValue.stakeValue;
      } else {
        formVersionForGrid["stake"] = 0.0;
      }

      const details = JSON.parse(formVersion.details);

      formVersionForGrid["frozen"] = details.frozen;
      formVersionForGrid["status"] = details.status;

      formVersionForGrid["versionName"] = JSON.parse(formVersion.details).versionName;
      formVersionForGrid["currency"] = formVersion?.investment?.currency;

      this.assignApprovalStatusToVersion(formVersionForGrid, formVersion);
      
      this.valuationDateGridDataSource.push(formVersionForGrid);
  }

  assignApprovalStatusToVersion(formVersionForGrid, formVersion) {
    let obj = {} as any;

    if(this.showValDatesBeta) {
      // New Approval System
      // obj = this.approvalService.getApprovalStatus(formVersion);
      
      if (!!formVersionForGrid.approvalSystem) {
        formVersionForGrid.selectedCondition = {
          "conditionName": Object.values(formVersionForGrid.approvalSystem.condition)[0],
          "id": Object.keys(formVersionForGrid.approvalSystem.condition)[0]
        }
      }
      if(!!formVersionForGrid.approvalCondition){
        formVersionForGrid.selectedCondition['id'] = formVersionForGrid.approvalCondition;

        let updatedCondition = this.approvalService.allEnabledConditions.find( c => c.id == formVersionForGrid.approvalCondition);

        if(!!updatedCondition){
          formVersionForGrid.selectedCondition['conditionName'] = updatedCondition.conditionName;
        }

      }
      if(!!formVersion){
        formVersionForGrid["editable"] = !formVersion.approvalSystem;
      }else{
        formVersionForGrid["editable"] = true;
      }
    } else {
      // Old Approval Matrix
      obj = this.approvalMatrixService.getApprovalStatus(this.allApprovalDetails, this.approvalConditionData, this.initiatorsGroupMembers, formVersion);
      
      formVersionForGrid["approvalStatus"] = obj["approvalStatus"];
      formVersionForGrid["actionName"] = obj["actionName"];
      formVersionForGrid["editable"] = obj["editable"];
      formVersionForGrid["approvalRequestId"] = obj["approvalRequestId"];
  
      formVersionForGrid["approvalUserData"] = obj["approvalUserData"];
      formVersionForGrid["comments"] = null;
    }
  }

  initiateApprovalSystem() {

    setTimeout(()=>{

      this.showByTopMenu('vd'); 

      this.showValDatesBeta = true; 
      this.invSummaryService.showSettings = false;

      this.valuationDateGridDataSource.forEach(formVersionForGrid => {
        let formVersion = {};

        formVersionForGrid.value.forEach(eachVersion => {
          formVersion = this.selectedCompanyDates.find(date => date.id == eachVersion.id);

          this.assignApprovalStatusToVersion(eachVersion, formVersion);
        })
      })
      this.fetchCommentsForAllValuationDates()
    })
  }

  fetchCommentsForAllValuationDates() {
    if(!this.commentService.fetchComments){
      this.valuationDateGridDataSource.forEach((element) => {
        element.value.forEach((item)=>{
          this.getAllComments(item);
        })
      });
    }
  }

  fetchCommentsAndRefreshTable(formId){
    let form;

    this.valuationDateGridDataSource.forEach((element) => {
      element.value.forEach((item)=>{
        if(item.id == formId){
          form = item;
          return;
        }
      })
    });

    this.getAllComments(form);
    if(this.valuationDatesGrid) {
      this.valuationDatesGrid.refresh();
    }
  }

  getAllComments(item){
    this.ds.getAllCommentsByFormId(item.id).subscribe(res => {
      let apiData = res.body["response"]

      let sortComment = apiData.sort((f1, f2)=>{
        const f1Date = new Date(f1.createdDate );
        const f2Date = new Date(f2.createdDate );
        return f1Date === f2Date? 0: f1Date > f2Date? 1: -1;
      })
  
      apiData = sortComment
      
      item.showIcon = apiData.length > 0 ? true : false
      this.commentService.commentsForValuationDate[item.id] = apiData
      this.commentService.fetchComments = true;
    })
  }

  selectCondition(e, item){
    item.selectedCondition = this.approvalService.allEnabledConditions.find( c => c.id == e.value);

    this.ds.updateSelectedApprovalConditionForEachFormId(item.selectedCondition.id, item.id).subscribe(res=> {
        console.log("Approval condition has been updated")
    }, err =>{
      console.log("Failed to update Approval condition: ", err)
    })
  }

  getLoggedInUserId() {
    return this.loggedInUser.id;
  }

  prepareGroupledValVersions(){
    let data = this.valuationDateGridDataSource;
    let dataMap = new Map();
    data.forEach(v => {
      if(dataMap.has(v.valuationDate)){
        let arr = dataMap.get(v.valuationDate);
        arr.push(v);
        dataMap.set(v.valuationDate, arr);
      }
      else dataMap.set(v.valuationDate, [v]);
    });

    let obj = {};
    let dataArr = [];
    let count = 0;
    for(let item of dataMap){
      obj = { name : item[0], value : item[1], index : count};
      dataArr.push(obj);
      count++;
    }

    //sorting versions inside a valuation date 
    dataArr.forEach( valDate => {
      valDate.value.sort((a,b) =>{
          return new Date(a.createdDate).valueOf() - new Date(b.createdDate).valueOf()
      })
    })

    console.log("dataARR", dataArr);
    this.valuationDateGridDataSource = cloneDeep(dataArr);

    this.approvalService.init(this.primaryCompanyId, this.valuationDateGridDataSource);
  }

  checkIfLatestValDateIsMBU(comp){
    if(comp && comp.businessUnitsNumber && comp.businessUnitsNumber > 0 && comp.consolForm == null){
      const details = JSON.parse(comp.details);

      return details && details.businessUnitsNumber > 0
    }

    return false
  }

  async showByTopMenu(ui, comp?) {
    this.showConsolSummary = false;
    this.showUserValDates = false;
    this.showValDates = false;
    this.showInvestment = false;

    if(ui === "vd") {
      this.showValDates = true;
      this.invSummaryService.showSettings = false;
    } else if(ui === "userVD") {
      this.showUserValDates = true;
      this.invSummaryService.showSettings = false;
    } else if(ui === "consol") {
      if(comp){
        try {
          const apiData = await this.ds.getBusinessUnitsByConsolFormWithInvestmentSummary(comp.id).toPromise()
          const data = this.invSummaryService.sortBusinessUnitsByBusinessUnitNameOrderByASC(apiData.body["response"]);
          const businessUnits = this.invSummaryService.addInfoToFormobjectFromDetails(data);
          comp.businessUnits = businessUnits;
        } 
        catch (error) {
          console.log("Failed to fetch BU info for : " + comp.id)
        }
        this.selectedCompanyConsolForm = comp //when top menu is clicked loading latestValuationDate Consol Summary
      }
      this.showConsolSummary = true;
      this.invSummaryService.showSettings = false;
    } else if(ui === 'INVESTMENT') {
      this.showInvestment = true;
    }
  }

  showBuildUps(){
    this.showBuildUpSummary = true;
    this.invSummaryService.showSettings = false;
  }

  copyVersion(selectedVersion) {
    this.showVersionNameDialog = true;
    this.selectedVersionForCopy = selectedVersion;
  }

  async deleteCompany(company){

    if(!company.editable) return;

    let upperLimit = null;

    let comapnyIdList :string[] = [company.id];
    
    const companyValDate = this.datePipe.transform(company.valuationDate, 'mediumDate');
    const versionName = " (" + this.titlecasePipe.transform(company.versionName) + ")";

    const confirm = this.utilService.showConfirmMessage(this.translateService.getLabel("info_confirm_delete") + " - " + companyValDate + versionName, this.translateService.getLabel("yes"), this.translateService.getLabel("no"));
    
    confirm.afterClosed().subscribe(confirmation => { 
      if(confirmation === "Yes") {

        if(!company.userEntered) {
          
            const orgId = this.ums.getSelectedUserDetails().organization.id;

            this.ds.deleteCompanies(comapnyIdList, orgId).subscribe(async response => {
              // console.log("Successfully Deleted");
              this.modalService.dismissAll(); 

              this.deleteCompanyFromList(company);

              this.utilService.showMessage(this.translateService.getLabel("suc_delete") + " - " + companyValDate + versionName, this.translateService.getLabel("ok"));

              // try {
              //   const valDateUpperLimitSavedAPI = await this.ds.getWidgetDataFromDB("VAL_DATES_UPPERLIMIT", this.primaryCompanyId).toPromise();
              //   upperLimit = valDateUpperLimitSavedAPI.body["response"][0]["widgetData"].limit;

              //   //Checking if the deleted company's Valuation date is Saved in Upper Limit Widget
              //   if(upperLimit && company.valuationDate == upperLimit){
              //     const latestValDateAfterDeletingTheForm = this.invSummaryService.allValuationDates.find(comp => { 
              //       const details = JSON.parse(comp.details);
              //       return details.status == "Submitted" && comp.valuationDate != company.valuationDate 
              //     });

              //     if(latestValDateAfterDeletingTheForm){
              //       const reqBody = {
              //         limit: latestValDateAfterDeletingTheForm.valuationDate
              //       }
              //       this.ds.saveWidgetToDBAsync("VAL_DATES_UPPERLIMIT", reqBody, this.primaryCompanyId);

              //       const latestValDate = this.invSummaryService.allValuationDates.find(form => {
              //         const details = JSON.parse(form.details);
              //         return details.status == "Submitted";
              //       })
              //       this.valDateUpperLimit = latestValDate 
              //       ? latestValDate.valuationDate 
              //       : this.invSummaryService.allValuationDates[0].valuationDate;
              //     }
              //   }

              // } 
              // catch(e) {
              //   if(!upperLimit){
              //     const latestValDate = this.invSummaryService.allValuationDates.find(form => {
              //       const details = JSON.parse(form.details);
              //       return details.status == "Submitted";
              //     })
              //     this.valDateUpperLimit = latestValDate 
              //     ? latestValDate.valuationDate 
              //     : this.invSummaryService.allValuationDates[0].valuationDate;
              //   }
              // }

              const latestValDate = this.invSummaryService.allValuationDates.find(form => {
                  const details = JSON.parse(form.details);
                  return details && details.status == "Submitted";
              })
              this.valDateUpperLimit = latestValDate 
              ? latestValDate.valuationDate 
              : this.invSummaryService.allValuationDates[0].valuationDate;

              this.ngOnInit();
            }, error => {
              console.log("Error While Deleting Companies", error);
              this.modalService.dismissAll();
              this.utilService.showMessage(this.translateService.getLabel("err_failed_delete") + " - " + companyValDate + ". " + this.translateService.getLabel("info_try_after_sometime"), this.translateService.getLabel("ok"), true);
            });
        } else {
          //------------------------------------------------------------------------------
          // Uncomment this code once user saved valuation dates need to be brought back.
          //------------------------------------------------------------------------------

          /******
          // this.dataService.saveWidgetDataToDB(this.portfolioService.DATA_KEY_OLD_VALUATION, JSON.stringify(this.portfolioService.userSavedOldFormsExcel[this.primaryCompanyId]), this.primaryCompanyId).subscribe(res=>{
     
          const savedIndex = this.portfolioService.userSavedOldFormsExcel[this.primaryCompanyId].findIndex(comp => comp.id === company.id);
          this.portfolioService.userSavedOldFormsExcel[this.primaryCompanyId].splice(savedIndex, 1);

          //Save updated list of user entered companies
          this.ds.saveWidgetDataToDB(this.portfolioService.DATA_KEY_OLD_VALUATION, JSON.stringify(this.portfolioService.userSavedOldFormsExcel[this.primaryCompanyId]), this.primaryCompanyId).subscribe(res=>{
            
            this.deleteCompanyFromList(company);

            this.utilService.showMessage("Successfully deleted - " + companyValDate + versionName, "Ok");

          }, err => {
            this.utilService.showMessage("Error: Failed to delete - " + companyValDate + ". Please try after sometime.", "Ok", true);
          }); 
          */
        }
      }
    });
  }

  deleteCompanyFromList(company) {
    const displayCompIndex = this.selectedCompanyDates.findIndex(c => c.id === company.id);
    if(displayCompIndex >= 0) {
      this.selectedCompanyDates.splice(displayCompIndex, 1)
    }

    const companyIndex = this.selectedCompanyDates.findIndex(c => c.id === company.id);
    if(companyIndex >= 0) {
      this.selectedCompanyDates.splice(companyIndex, 1)
    }

    const toBeDeletedIndex = this.selectedCompanyDatesWithAllVersions.findIndex(c => c.id === company.id);
    this.selectedCompanyDatesWithAllVersions.splice(toBeDeletedIndex, 1);

    this.invSummaryService.allValuationDates = this.invSummaryService
                    .addInfoToFormobjectFromDetails(this.selectedCompanyDatesWithAllVersions.filter(comp => comp.frozen))
                    .sort( function(a,b) { return (b.valuationDate) - (a.valuationDate) });

    this.utilService.showLoadingPopup();
    this.prepareDataSourceForAllValDatesWithVersions();
  }

  async openValuation(comp, businessUnitPopup, forceOpen?) {
    this.modalService.dismissAll();

    if(comp.businessUnitsNumber && comp.businessUnitsNumber > 0 && comp.consolForm == null){
      if(!forceOpen) {
        // this.buShareMode = false;
        // this.buEditMode = false;
        this.mode = this.typeOfModes.VIEW_TYPE;
        await this.openBusinessUnits(comp, businessUnitPopup)
      }
      else {
        await this.showByTopMenu('consol');
      }
      return;
    }

    this.openValuationWithNormalDates(comp);
  }

  openValuationWithNormalDates(comp, additionalParams="") {
    //Ignore if it is user added old valuation date
    if(!comp.id || comp.id.indexOf("_S") > 0) return;

    //Ignore if it is Consol form (which has business units)
    if(comp.businessUnits && comp.businessUnits.length > 0) return;

    const version = comp.formVersion; //this.ums.getApplicationVersion() + "";

    localStorage.setItem('formId', comp.id);
    localStorage.setItem('fv', version);
    localStorage.setItem('qubit-val-date', comp.valuationDate);
    localStorage.setItem('qubit-investment-date-id', this.primaryCompanyId);
    localStorage.setItem("FUND_ID", comp.fundCompany);
    let fundName= this.fundService.getFundName(comp.fundCompany);
    let versionName=comp.versionName;
    let urlParameters = "id=" + comp.id + "&fv=" + version + "&fundName="+fundName + "&versionName="+versionName;
    if(comp.consolForm && this.selectedCompanyDatesWithAllVersions && this.selectedCompanyDatesWithAllVersions.length > 0) {
      const consolForm = this.selectedCompanyDatesWithAllVersions.find( f => f.id == comp.consolForm);
      urlParameters += "&consol=" + consolForm.companyNameInForm;
    }
    this.ums.addUserAction("Valuation Date Selection", comp.id, comp.companyName+ " | " +comp.valuationDate, "Investment Summary");
    let url = environment.portalUrl + environment.pvValuation + "/#/valuation-summary?" + urlParameters + "&parentId=" + comp.fundCompany;

    if(comp.approvalRequestId) {
      url += "&aid="+comp.approvalRequestId;
    }

    if(additionalParams) {
      url += additionalParams;
    }

    window.open(url, "_self")
  }

  openSharePopUp(comp, businessUnitPopup, valuationSharePopUp){
    if(comp.businessUnitsNumber && comp.businessUnitsNumber > 0 && comp.consolForm == null){

      // this.buShareMode = true;
      this.mode = this.typeOfModes.SHARE_TYPE;
      this.openBusinessUnits(comp, businessUnitPopup)
      return
    }

    this.openValuationSharePopUp(comp, valuationSharePopUp)
  }

  openValuationDatePopup(content){

    this.disableRollOver() ? this.utilService.showMessage("Please perform the initial valuation prior to rolling over. In order to do so please click on show valuation date and submit the form.", "Ok")
    : this.modalService.open(content, { centered: true, windowClass: 'valuationDate-popup-modal'})
  }

  async openBusinessUnits(comp, content) {
    this.selectedBusinessUnits = null;

    // this.utilService.showLoadingPopup();
    this.modalService.open(content, { centered: true })

    const consolForm = this.selectedCompanyDatesWithAllVersions.find(f => f.id === comp.id) || comp;

    try {
      const apiData = await this.ds.getBusinessUnitsByConsolFormWithInvestmentSummary(comp.id).toPromise();
      const data = this.invSummaryService.sortBusinessUnitsByBusinessUnitNameOrderByASC(apiData.body["response"]);
      const businessUnits = this.invSummaryService.addInfoToFormobjectFromDetails(data);
      consolForm.businessUnits = businessUnits;
    } 
    catch (error) {
      console.log("Failed to fetch BU info for : " + comp.id)
    }

    this.selectedCompanyConsolForm = cloneDeep(consolForm); //loading consol summary selected valuation date, passing as an input to consol summary

    this.utilService.closeAllPopups();
    if(consolForm && consolForm.businessUnits) {
      this.selectedBusinessUnits = [];

      consolForm.editable = comp.editable;

      // Add consol form also in edit mode so that consol form can be opened into SAF
      // if(this.buEditMode) {
        this.selectedBusinessUnits = this.selectedBusinessUnits.concat(consolForm);
      // }

      if(consolForm.status !== "Initiated") {
        this.selectedBusinessUnits = this.selectedBusinessUnits.concat(consolForm.businessUnits);

        consolForm.businessUnits.forEach(form => {

          form.editable = comp.editable;

          const details = JSON.parse(form.details);

          if(details) {  
            if(!form.companyName) {
              form.companyName = details["companyName"];
            }
            form.currency = details["currency"];
            form.versionName = details["versionName"];
            form.businessUnitName = details["businessUnitName"];
            form.status = details["status"];
          }
        })

      }

    } 
    else {
      this.openSAF(comp, null, true)
    }
  }

  openSAF(comp, businessUnitPopup, forceEdit?) {
    this.modalService.dismissAll();

    if(!comp.editable) return;

    if(!forceEdit && comp.businessUnitsNumber && comp.businessUnitsNumber > 0 && comp.consolForm == null){
      // this.buShareMode = false;
      // this.buEditMode = true;
      this.mode = this.typeOfModes.EDIT_TYPE;
      this.openBusinessUnits(comp, businessUnitPopup)
      return;
    }

    this.openSAFOnValuationDateRollOverCreation(comp);
  }

  openSAFOnValuationDateRollOverCreation(comp){
    let fundName= this.fundService.getFundName(comp.fundCompany);
    let versionName = comp.versionName;

    let consolFormObj = null;
    if(comp.consolForm) {
      let consolForm = this.selectedCompanyDatesWithAllVersions.find(f => f.id === comp.consolForm);

      if(!consolForm) {
        consolForm = this.selectedCompanyDatesWithAllVersions.find(f => f.id === this.primaryCompanyId);
      }
        
      consolFormObj = {
        srcCurrency: consolForm.currency ,
        consol: consolForm.companyNameInForm,
        marketDate: consolForm.marketDate
      }
    }

    this.utilService.navigateToSAFForm(comp.id, this.primaryCompanyId, comp.valuationDate, 
      versionName, consolFormObj, 
      comp.fundCompany, fundName);
  }

  // addValuationDate() {

  //   if(!this.newCompany.valuationDate || this.newCompany.valuationDate.length === 0) return;

  //   this.newCompany.valuationDate = (moment(this.newCompany.valuationDate)).format("YYYY-MM-DD");

  //   //Not allowing user to create a new Valuation Date if the same date already exists
  //   const valDateAlreadyAExists = this.invSummaryService.allValuationDates.find(comp => comp.valuationDate == this.newCompany.valuationDate);
  //   if(valDateAlreadyAExists){
  //     this.utilService.showMessage("Valuation Date already exists.", "Ok", true);
  //     this.newCompany = {name: "", valuationDate: null, groupFormId: null, security: "EQUITY", businessUnitsNumber: 0, versionName:""};
  //     return;
  //   }

  //   let latestFormToCopy = this.invSummaryService.allValuationDates.find( comp => !comp.userEntered && comp.status !== "Initiated" && comp.frozen)
    
  //   this.createValuationDate(this.newCompany, "FIRST", true, latestFormToCopy);
  // }

  // Add new valuation date / replicate the existed valuation date.
  addValuationDateDetails(event){
    if(!this.newCompany.valuationDate || this.newCompany.valuationDate.length === 0) return;

    const valDateAlreadyAExists = this.invSummaryService.allValuationDates.find(comp => comp.valuationDate == this.newCompany.valuationDate);

    if(valDateAlreadyAExists){
      this.utilService.showMessage("Valuation Date already exists.", "Ok", true);

      const invDateForm = this.selectedCompanyDates.find(comp => comp.groupFormId == null);

      this.newCompany = {name: invDateForm.companyName, valuationDate: null, groupFormId: this.primaryCompanyId, security: "EQUITY", businessUnitsNumber: this.invSummaryService.allValuationDates[0].businessUnitsNumber,
       versionName: "" , replicatedValDate: null, replicatedValDateId: null, updateMarketData:true, marketDataDate: "", refreshInvesteefinancials:false, financialRefreshDate:""};
      return;
    }

    let formToCopy;
    
    //  Replicate Valuation Date Creation
    if (this.newCompany["valuationType"] == 'replicate') {
      formToCopy = this.invSummaryService.allValuationDates.find(comp => comp.id == this.newCompany.replicatedValDateId);
    
    } else {
      //Re-value
      formToCopy = this.invSummaryService.allValuationDates.find(comp => !comp.userEntered && comp.status !== "Initiated" && comp.frozen)
    }

    this.createValuationDate(this.newCompany, this.newCompany.versionName, true, formToCopy);
  }

  createValuationDate(newCompanyInfo, versionName, frozen, copyDataFromForm) {

    this.utilService.showLoadingPopup();
    this.ms.publish("loadingMessage", this.translateService.getLabel("preparing_data_for_valuation_date"));

    let loggedInUserDetails = this.ums.getSelectedUserDetails();
    
    newCompanyInfo.oldConsolForm = copyDataFromForm? copyDataFromForm.id : null;

    const invDateForm = this.selectedCompanyDates.find(comp => comp.groupFormId == null);
    const loggedInUser = this.ums.getUserDetails();

    this.ds.createNewCompanyWithVersion(newCompanyInfo, loggedInUserDetails, this.ums.getApplicationVersion(), versionName, frozen, loggedInUser, invDateForm.fundCompany).subscribe(res=>{
      // console.log("Company Created", res.body);

      const newCompanyCreated = res.body["response"];

      if(frozen) {
        this.portfolioService.prepareCompanyData([ newCompanyCreated ]);

        // this.selectedCompanyDates = this.portfolioService.getCompanyAllValuationDates( this.primaryCompanyId );
      }
      
      if(!copyDataFromForm) {
        // None of the forms are submitted. So skip copying answers
        this.portfolioService.assignUsersToForm(newCompanyCreated, this.newCompany.name);

        this.openSAFOnValuationDateRollOverCreation(newCompanyCreated); //navigating to SAF on valuation Date rollover creation
      } else {

          this.ds.copyFormAnswers(copyDataFromForm.id, newCompanyCreated.id).subscribe((saveResult)=>{
            // console.log("copy answers is successful", saveResult);

            this.portfolioService.assignUsersToForm(newCompanyCreated, this.newCompany.name);
            this.openSAFOnValuationDateRollOverCreation(newCompanyCreated); //navigating to SAF on valuation Date rollover creation

          }, (error)=>{
            console.log("error in copying form answers", error);

            this.portfolioService.assignUsersToForm(newCompanyCreated, this.newCompany.name);
            this.openSAFOnValuationDateRollOverCreation(newCompanyCreated); //navigating to SAF on valuation Date rollover creation
          }); 
      }

      this.utilService.closeAllPopups()

      //instead of reloading the Component, navigating to SAF on valuation Date rollover creation
      // this.ngOnInit() 
    }, err=>{
      this.utilService.closeAllPopups()
      console.log("Error while creating company", err)
      this.utilService.showMessage(this.translateService.getLabel("err_failed_create") + this.newCompany.name + " " + this.translateService.getLabel("valuation"), this.translateService.getLabel("ok"));
    })
    
  }

  reloadInvestSummary(){
    this.loadInvSummary = false;
    setTimeout(() => {
      this.loadInvSummary = true; 
    });
  }


  showVersionComparisonUI = false;
  versionsForCompare = [];

  showVersionComparison(data) {
    this.showVersionComparisonUI = true;
    this.versionsForCompare = data.value;
  }

  addValuationDateVersion() {
    if(!this.newFormVersion || this.newFormVersion.length === 0) return;

    // const selectedVersion = this.selectedFormVersions.find(fv => fv.frozen);
    const selectedVersion = this.selectedVersionForCopy;
    
    this.newCompany.name = selectedVersion.companyName;
    this.newCompany.groupFormId = this.primaryCompanyId;
    this.newCompany.valuationDate = selectedVersion.valuationDate;
    
    // When creating A Version, REPLICATING by Default
    this.newCompany.replicatedValDateId = selectedVersion.id;
    this.newCompany["valuationType"] = this.invSummaryService.TYPE_OF_SUBMISSION.REPLICATE;
    this.newCompany["updateMarketData"] = false;
    this.newCompany["refreshInvesteefinancials"] = false;

    this.newCompany.businessUnitsNumber = selectedVersion.businessUnitsNumber ? selectedVersion.businessUnitsNumber: 0;

    this.modalService.dismissAll();

    this.createValuationDate(this.newCompany, this.newFormVersion, false, selectedVersion);
    this.hideVersionNameDialog();
  }

  hideUpdateVersionNameDialog() {
    this.showUpdateVersionNameDialog = false;
    this.selectedVersionForUpdate = null;
  }

  hideVersionNameDialog() {
    this.showVersionNameDialog = false;
    this.selectedVersionForCopy = null;
  }

  updateVersion(selectedVersion) {
    this.showUpdateVersionNameDialog = true;
    this.selectedVersionForUpdate = selectedVersion;
  }

  selectVersionV2(allVersionsInValuationDate, selectedVersion) {
    
    //The version is already frozen
    const totalFrozenVersions = allVersionsInValuationDate.filter(fv => fv.frozen);

    if(totalFrozenVersions && totalFrozenVersions.length === 1){
      if(selectedVersion.frozen || !selectedVersion.editable) return;
    }
    
    this.modalService.dismissAll();

    this.utilService.showLoadingPopup();

    const oldSelectedVersion = allVersionsInValuationDate.find(fv => fv.frozen);

    this.ds.freezeFormVersion(oldSelectedVersion.valuationDate, oldSelectedVersion.id, selectedVersion.id).subscribe(res => {
      // console.log("Freezing new form version is done");

      const dialog = this.utilService.showMessage(this.translateService.getLabel("info_valuation_date_frozen"), this.translateService.getLabel("ok"))
      dialog.afterClosed().subscribe(()=>{
        
        window.location.reload();
      })
    }, error =>{
      console.log("Error while freezing form version - " + selectedVersion.versionName);
      this.utilService.showMessage(this.translateService.getLabel("err_failed_freeze_form") + " - " + selectedVersion.versionName, this.translateService.getLabel("ok"), true);
    })
  }
  
  updateVersionName(){
    const selectedVersionData = this.selectedVersionForUpdate;

    const reqBody = {
      "id": selectedVersionData.id,
      "versionName": this.newVersionName
    };

    this.ds.saveUpdatedVersionName(reqBody).subscribe(res => {
      const apiResponse = res.body["response"];

      selectedVersionData.versionName = this.newVersionName;
      
      this.utilService.showMessage(this.translateService.getLabel("suc_update_version"), this.translateService.getLabel("ok"));
    }, error => {
      this.utilService.showMessage(this.translateService.getLabel("err_failed_update_version_name"), this.translateService.getLabel("ok"), true);
    });

    this.hideUpdateVersionNameDialog();
  }

  openCurrencyDashboardPopup(content) {
    this.modalService.open(content, { centered: true })
  }

  // ----  ----  ----  ----  ----  ----  ----  ----  ---- 
  // ---- Valuation Date Share to external user ---- 
  openValuationSharePopUp(form, content) {
      
    this.shareInput.sharePermissions.forEach(val => { val.selected = false });
    
    this.selectedFormToShare = form;

    this.modalService.open(content, { centered: true , windowClass :'external-share-popup'})
  }

  share(popUp) {
    // console.log(this.shareInput);

    if (this.shareInput.users && this.shareInput.users.length > 0 && this.isValidShareInput(this.shareInput.users)) {

        const selectedTabs = this.shareInput.sharePermissions.filter(tab => tab['selected'] )
        if(selectedTabs.length > 0) {
          this.shareInput['sharedTabNames'] = selectedTabs.map( tab => tab.label).join(", ");
        } else {
          //No tabs are selected. Stay on the Input Popup.
          return;
        }

        popUp.dismiss('Cross click');

        this.shareInput["valuationDate"] = this.datePipe.transform(this.selectedFormToShare.valuationDate, "MMM dd, yyyy");
        this.shareInput["companyName"] = this.newCompany.name;

        this.ds.shareFormToExternalUser(this.selectedFormToShare.id, this.shareInput).subscribe(res => {
          
          let shareInputUserNames = this.shareInput.users.map(obj=>obj.name).toString();
          this.utilService.showMessage(this.translateService.getLabel("valuation_date") + " (" + this.selectedFormToShare.valuationDate + ") " + this.translateService.getLabel("is_shared_to") + " " + shareInputUserNames, this.translateService.getLabel("ok"));
        }, error => {
          
          this.utilService.showMessage(this.translateService.getLabel("err_failed_share_valuation_date") + " (" + this.selectedFormToShare.valuationDate + ")", this.translateService.getLabel("ok"), true);
        })
        
    } else {
      // Stay on the Input Popup.
    }    
  }

  isValidShareInput(users) {
    users.forEach(obj => {
      if (!(obj.name.length > 3 && obj.email.length > 3)) {
        return false;
      }
    });
    return true;
  }

  shareInput = {
    users: [],
    name: null,
    email: null,
    sharePermissions: [
      
      {
        label: this.translateService.getLabel('valuation_summary'),
        path: '/valuation-summary',
        id: 'valuation-summary',
        selected: false
      },
      {
        label: this.translateService.getLabel('valuation'),
        path: '/valuation',
        id: 'valuation',
        selected: false
      },
      {
        label: this.translateService.getLabel('data_tab'),
        path: '/data',
        id: 'data',
        selected: false
      },
      {
        label: this.translateService.getLabel('form'),
        path: '/form',
        id: 'form',
        selected: false
      },
      {
        label: this.translateService.getLabel('valuation_tracker'),
        path: '/valuation-tracker',
        id: 'valuation-tracker',
        selected: false
      },
      {
        label: this.translateService.getLabel('benchmarking'),
        path: '/benchmarking',
        id: 'benchmarking',
        selected: false
      },
      {
        label: this.translateService.getLabel('kpi'),
        path: '/kpi',
        id: 'kpi',
        selected: false
      },
      {
        label: this.translateService.getLabel('additional_analysis'),
        path: '/additionalAnalysis',
        id: 'additionalAnalysis',
        selected: false
      },      
      {
        label: 'Cap Table + Waterfall Analysis',
        path: '/captable-waterfall-version',
        id: 'captable-waterfall-version',
        selected: false
      },
      {
        label: this.translateService.getLabel('files'),
        path: '/files',
        id: 'files',
        selected: false
      },
      {
        label: this.translateService.getLabel('audit_trail'),
        path: '/auditTrail',
        id: 'auditTrail',
        selected: false
      },
      {
        label: this.translateService.getLabel('news_analysis'),
        path: '/reviewAnalysis',
        id: 'reviewAnalysis',
        selected: false
      },
      {
        label: this.translateService.getLabel('ect_analysis'),
        path: '/ectAnalysis',
        id: 'ectAnalysis',
        selected: false
      }
    ]
  }

  checkUncheckAll(){
    if (this.shareInput.sharePermissions.every(val => val.selected == true))
      this.shareInput.sharePermissions.forEach(val => { val.selected = false });
    else
      this.shareInput.sharePermissions.forEach(val => { val.selected = true });
  }

  emitAction(event, valuationSharePopUp){
    if(event.type === "VIEW_SAF"){
      this.openSAF(event.bu, null, !!event.bu.businessUnits)
    }
    else if(event.type === "VIEW_VALUATION"){
      this.openValuation(event.bu, null, !!event.bu.businessUnits)
    }
    else if(event.type === "VIEW_SHARE"){
      this.openValuationSharePopUp(event.bu, valuationSharePopUp)
    }

    this.mode = this.typeOfModes.VIEW_TYPE;
  }

  upperLimitChanged(upperLimit){
    this.valDateUpperLimit = upperLimit;
    this.ngOnInit(this.valDateUpperLimit);

    this.utilService.closeAllPopups();
  }

  navClicked(headerLabel) {
    if (headerLabel == 'InvestmentSummary') {
      this.showConsolSummary = false;
    }
  }
}
