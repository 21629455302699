import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/services/translation.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { environment } from 'src/environments/environment';
import { PortFolioSummaryServiceV2 } from '../portfolio-summary-v2/portfolio-summary-v2.service';
import { CheckListService } from './check-list.service';

@Component({
  selector: 'app-check-list-pop-up',
  templateUrl: './check-list-pop-up.component.html',
  styleUrls: ['./check-list-pop-up.component.scss']
})
export class CheckListPopUpComponent implements OnInit {

  companylistForCheckList: any;

  openChecklist = false;

  env = environment.environment;

  constructor(
    public checkListService: CheckListService,
    private ps: PortFolioSummaryServiceV2,
    public ums: UserManagementService,
    private ds: DataService,
    public translateService : TranslateService) 
  { }

  async ngOnInit() {
    //Since the Checkist Data needs to be refreshed/fetched on every pop over open click, we should avoid calling the same APIs on platform loads.
  }

  prepareCompanyList(){
    setTimeout(() => {
      this.checkListService.dataLoaded = true;
    }, 3000);
  }

  async refreshChecklist(){

    this.openChecklist = !this.openChecklist

    if(this.openChecklist){
      this.checkListService.dataLoaded = false;
      await this.checkListService.init(this.ps.selectedFundId);
      this.companylistForCheckList = this.checkListService.companies;
      this.checkListService.companies.forEach( comp => (comp.expanded = false))
      this.prepareCompanyList();
    }
  }

  async refreshChecklistOnRejection(){
    this.checkListService.dataLoaded = false;
    await this.checkListService.init(this.ps.selectedFundId);
    this.companylistForCheckList = this.checkListService.companies;
    this.checkListService.companies.forEach( comp => (comp.expanded = false))

    this.prepareCompanyList();

  }

}
